import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Row, Space, message } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../../../app/hooks";
import { selectUser } from "../../../auth/states/userSlice";
import { Fixed2 } from "../../../common/utils/common.utils";
import postDateFormat from "../../../common/utils/postDateFormat";
import {
  DateInput,
  FormButton,
  FormInput,
  FormInputItem,
  NumberInput,
  TextAreaInput,
} from "../../../components/common/FormItem/FormItems";
import {
  SelectClients,
  SelectDataInput,
  SelectEmployee,
} from "../../../components/common/FormItem/SelectCustomFeilds";
import SelectPaymentMethod from "../../../components/common/paymentMethod/SelectPaymentMethod";
import LoadingIndicator from "../../../components/common/spinner/LoadingIndicator";
import { Invoices } from "../../../components/notificatioin/Interfaces/Notification.interface";
import { ISelectClientDetails } from "../../IATA_AIR_TICKET/types/invoiceAirTicketTypes";
import { useGetAccountByTypeQuery } from "../../vendor/api/endpoints/vendorPaymentEndpoints";
import AccountLastBalanceInput from "../../vendor/components/AccountLastBalanceInput";
import {
  IMoneyReceiptInvoices,
  IMoneyReceiptPostType,
  IMoneyReceiptTickets,
} from "../Types/MoneyReceiptTypes";
import {
  useEditMoneyReceiptMutation,
  useLazyGetClientInvoicesInfoQuery,
  useLazyGetForEditQuery,
  usePostMoneyReceiptMutation,
} from "../api/endpoints/moneyReceiptEndpoints";
import NewMoneyReceiptSpecificInvoice from "./NewMoneyReceiptSpecificInvoice";
type Props = {
  reason: "ADD_NEW" | "EDIT";
  data?: Invoices;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};
const MoneyReceiptForm = ({ reason, data, setOpen }: Props) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [selectClient, setSelectclient] = useState<string | number>();
  const [paymentTo, setPaymentTo] = useState<string | undefined>();
  const [paymentMethod, setPaymentMethod] = useState<number>();
  const [payMethodCatId, setPayMethodCatId] = useState<number | undefined>(1);
  const { data: accounts, refetch: refetchAccount } = useGetAccountByTypeQuery(
    Number(payMethodCatId)
  );
  const [
    postMoneyReceipt,
    {
      isError: postIsError,
      data: postData,
      isSuccess: postIsSuccess,
      isLoading: postLoading,
    },
  ] = usePostMoneyReceiptMutation();

  useEffect(() => {
    form.setFieldValue("account_id", undefined);
  }, [paymentMethod]);

  const [getClientInvoicesInfo, { data: clientInvoicesInfo }] =
    useLazyGetClientInvoicesInfoQuery();

  useEffect(() => {
    if (
      clientInvoicesInfo?.data?.client_lbalance ||
      clientInvoicesInfo?.data?.client_lbalance == 0
    ) {
      form.setFieldValue(
        "presentBalance",
        Math.abs(clientInvoicesInfo.data.client_lbalance)
      );
    } else {
      form.setFieldValue("presentBalance", 0);
    }
  }, [clientInvoicesInfo, paymentTo]);

  const resetFields = () => {
    form.setFieldsValue({
      invoices: [{}],
      tickets: [{}],
      receipt_total_amount: undefined,
    });
  };

  const handlePayto = (payToParams: String, clientId: string) => {
    if (payToParams) {
      switch (payToParams) {
        case "OVERALL":
          getClientInvoicesInfo({
            id: clientId,
            receipt_payment_to: "OVERALL",
          });
          resetFields();
          break;
        case "INVOICE":
          getClientInvoicesInfo({
            id: clientId,
            receipt_payment_to: "INVOICE",
          });
          resetFields();
          break;
        case "TICKET":
          getClientInvoicesInfo({ id: clientId, receipt_payment_to: "TICKET" });
          resetFields();
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (postIsError) {
      message.error("Something went wrong on post time");
    }
  }, [postIsError]);

  useEffect(() => {
    if (postIsSuccess) {
      navigate(`/moneyreceipt/view/${postData?.data?.receipt_id}`, {
        state: "/moneyreceipt",
      });
      message.success("Money Receipt Successfully Added");
      form.resetFields();
      setPaymentTo(undefined);
      setSelectclient(undefined);
      setPaymentMethod(undefined);
      setPayMethodCatId(undefined);
    }
  }, [postIsSuccess]);

  const invoices: IMoneyReceiptInvoices[] = useWatch(["invoices"], form);
  const tickets: IMoneyReceiptTickets[] = useWatch(["tickets"], form);

  useEffect(() => {
    if (paymentTo === "INVOICE") {
      form.setFieldValue(
        "receipt_total_amount",
        invoices?.reduce((a, b) => a + Number(b?.invoice_amount || 0), 0)
      );
    } else if (paymentTo === "TICKET") {
      form.setFieldValue(
        "receipt_total_amount",
        tickets?.reduce((a, b) => a + Number(b?.invoice_amount || 0), 0)
      );
    }
  }, [tickets, invoices]);

  useEffect(() => {
    refetchAccount();
  }, [payMethodCatId]);
  useEffect(() => {
    if (!id) {
      form.setFieldValue("receipt_payment_date", dayjs());
    }
    form.setFieldValue("receipt_payment_type", 1);
  }, []);

  // handle edit
  const { receipt_id } = useParams();
  const [getForEdit, { data: getForEditData, isLoading: getEditLoading }] =
    useLazyGetForEditQuery();
  const navigate = useNavigate();
  const [
    editMoneyReceipt,
    {
      isError: editIsError,
      isLoading: editIsLoading,
      isSuccess: editIsSuccess,
    },
  ] = useEditMoneyReceiptMutation();

  useEffect(() => {
    if (postIsError) {
      message.error("Something went wrong on Update time");
    }
  }, [editIsError]);

  useEffect(() => {
    if (editIsSuccess) {
      message.success("Money Receipt Successfully Updated");
      navigate(`/moneyreceipt/view/${receipt_id}`);
      form.resetFields();
      setPaymentTo(undefined);
      setSelectclient(undefined);
      setPaymentMethod(undefined);
      setPayMethodCatId(undefined);
    }
  }, [editIsSuccess]);

  useEffect(() => {
    if (reason === "EDIT" && receipt_id) {
      getForEdit({ receipt_id: receipt_id });
    }
  }, [receipt_id]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (reason === "EDIT") {
      const editableData = getForEditData?.data;

      if (editableData?.receipt_walking_customer_name) {
        form.setFieldsValue({
          receipt_walking_customer_name:
            editableData?.receipt_walking_customer_name,
        });
      }

      const editableDataInvoice = getForEditData?.data?.invoices;
      if (editableData?.receipt_payment_to) {
        setPaymentTo(editableData?.receipt_payment_to);
        switch (editableData.receipt_payment_to) {
          case "INVOICE":
            if (editableDataInvoice) {
              getClientInvoicesInfo({
                id: editableData.receipt_combclient,
                receipt_payment_to: "INVOICE",
              });
              setSelectclient(editableData.receipt_combclient);
            }
            break;
          case "TICKET":
            getClientInvoicesInfo({
              id: editableData.receipt_combclient,
              receipt_payment_to: "TICKET",
            });
            setSelectclient(editableData.receipt_combclient);
            break;
          case "OVERALL":
            break;

          default:
            break;
        }
      }
      if (editableData?.receipt_payment_type) {
        setPayMethodCatId(editableData?.receipt_payment_type);
      }
      if (
        editableData?.receipt_payment_to === "OVERALL" &&
        editableData?.receipt_combclient
      ) {
        setPaymentTo("OVERALL");
        setSelectclient(editableData?.receipt_combclient);
        getClientInvoicesInfo({
          id: editableData.receipt_combclient,
          receipt_payment_to: "OVERALL",
        });
      }

      timeoutId = setTimeout(() => {
        form.setFieldsValue({
          ...editableData,
          receipt_payment_date: editableData?.receipt_payment_date
            ? dayjs(editableData.receipt_payment_date)
            : undefined,
          cheque_withdraw_date: editableData?.cheque_withdraw_date
            ? dayjs(editableData.cheque_withdraw_date)
            : undefined,
          invoices: editableData?.invoices?.map((el) => {
            return {
              invoice_no: el.invoice_id,
              invoice_amount: el.invoice_amount,
              netTotal: el.netTotal,
              invoiceDate: el.invoiceDate ? dayjs(el.invoiceDate) : undefined,
              paid: el.paid,
              due: Number(el.netTotal || 0) - Number(el.paid || 0),
            };
          }),
          tickets: editableData?.tickets?.map((el) => {
            return {
              ticket_no: `${el.invoice_id}-${el.ticket_no}`,
              invoice_amount: el.invoice_amount,
              netTotal: el.netTotal,
              paid: el.paid,
              due: Number(el.netTotal || 0) - Number(el.paid || 0),
            };
          }),
        });
      }, 200);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [getForEditData]);

  const user = useSelector(selectUser);

  const onFinish = (values: IMoneyReceiptPostType) => {
    const formatedData: IMoneyReceiptPostType = {
      account_id: values.account_id,
      receipt_total_amount: Fixed2(values.receipt_total_amount),
      receipt_total_discount: Fixed2(values?.receipt_total_discount),
      receipt_combclient: values.receipt_combclient,
      received_by: values.received_by,
      receipt_created_by: user?.user_id as number,
      receipt_money_receipt_no: values.receipt_money_receipt_no || undefined,
      receipt_payment_date: dayjs(values.receipt_payment_date).format(
        "YYYY-MM-DD"
      ),
      receipt_payment_to: values.receipt_payment_to,
      receipt_payment_type: values.receipt_payment_type,

      cheque_bank_name: values.cheque_bank_name || undefined,
      cheque_number: values.cheque_number || undefined,
      cheque_withdraw_date: postDateFormat(values.cheque_withdraw_date),

      trans_no: values.trans_no || undefined,
      charge_amount: values.charge_amount || undefined,

      receipt_note: values.receipt_note || undefined,

      invoices: values.invoices
        ? values.invoices.map((el, index) => {
            return {
              invoice_id: el.invoice_no, //here invoice_no is invoice_id
              invoice_amount: el.invoice_amount,
            };
          })
        : undefined,
      tickets: values.tickets
        ? values.tickets.map((el, index) => {
            return {
              invoice_id: el?.ticket_no?.split("-")[0],
              ticket_no: el?.ticket_no?.split("-")[1],
              invoice_amount: el.invoice_amount,
            };
          })
        : undefined,
    };

    if (values?.receipt_walking_customer_name) {
      formatedData.receipt_walking_customer_name =
        values.receipt_walking_customer_name;
    }

    if (reason === "ADD_NEW") {
      setOpen && setOpen(false);
      postMoneyReceipt(formatedData);
    } else if (reason === "EDIT" && receipt_id) {
      editMoneyReceipt({ body: formatedData, id: receipt_id });
    }
  };
  //======================== ADD FROM NOTIFICATION =======================
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (data) {
      form.setFieldsValue({
        receipt_combclient: "client-" + data.client_id,
        receipt_payment_to: "INVOICE",
      });
      setSelectclient(data.client_id);
      getClientInvoicesInfo({
        id: String(data.client_id),
        receipt_payment_to: "INVOICE",
      });
      setPaymentTo("INVOICE");
      timeoutId = setTimeout(() => {
        form.setFieldValue(["invoices", 0, "invoice_no"], data.invoice_no);
      }, 500);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [data]);

  const [clientDetails, setClientDetails] = useState<ISelectClientDetails>();
  const getPrevFiledValue = form.getFieldValue("receipt_walking_customer_name");

  const role_name = useAppSelector((state) => state.user?.role_name);

  const editPermission = useAppSelector((sate) => sate.user?.role_permissions);
  const editPermissionParse = editPermission
    ? JSON.parse(editPermission)
    : undefined;
  const disableInvoiceEdit =
    editPermissionParse[role_name!]?.mony_receipt_specific_invoice;

  const disableTicketEdit =
    editPermissionParse[role_name!]?.mony_receipt_specific_ticket;

  const disableRemoveInvoiceIndex = getForEditData?.data?.invoices?.length;
  const disableRemoveTicketIndex = getForEditData?.data?.tickets?.length;

  return (
    <ClientAddStyle>
      <Space style={{ marginBottom: "1rem" }}>
        <Link to="/moneyreceipt">
          <Button type="primary">
            <ArrowLeftOutlined />
            Money Receipt List
          </Button>
        </Link>
      </Space>
      {(postLoading || editIsLoading || getEditLoading) && <LoadingIndicator />}

      <Form
        layout={"vertical"}
        form={form}
        onFinish={onFinish}
        onFinishFailed={(value) => {}}
        className="justify-center"
      >
        <div style={{ maxWidth: 900 }} className="border p-20">
          <Row gutter={24}>
            <SelectClients
              offDropDown
              label="Select Client"
              name="receipt_combclient"
              required
              size={12}
              setClientDetails={setClientDetails}
              onChange={(value) => {
                setSelectclient(value.toString());
                getClientInvoicesInfo({
                  id: String(value),
                  receipt_payment_to: "OVERALL",
                });
                form.setFieldValue("receipt_payment_to", "OVERALL");
                setPaymentTo("OVERALL");
              }}
            />

            <DateInput
              size={12}
              required
              label="Receipt Date"
              name={"receipt_payment_date"}
            />

            {(clientDetails?.client_walking_customer === 1 ||
              getPrevFiledValue) && (
              <FormInput
                size={12}
                label="Client Name"
                name="receipt_walking_customer_name"
              />
            )}

            <FormInputItem
              size={12}
              label={
                (clientInvoicesInfo?.data?.client_lbalance || 0) > 0
                  ? "Present Balance"
                  : "Present Due"
              }
              name={"presentBalance"}
              readOnly
              status={
                clientInvoicesInfo?.data?.client_lbalance! < 0 ? true : false
              }
            />

            <SelectDataInput
              mdSize={12}
              label="Payment To"
              name="receipt_payment_to"
              required
              disabled={selectClient || reason === "EDIT" ? false : true}
              data={[
                {
                  id: "OVERALL",
                  title: "Over All",
                },
                {
                  id: "INVOICE",
                  title: "Specific Invoice",
                },
                {
                  id: "TICKET",
                  title: "Specific Tickets",
                },
              ]}
              onChange={(value) => {
                if (selectClient && reason !== "EDIT")
                  handlePayto(String(value), selectClient as string);

                setPaymentTo(String(value));
              }}
              size={12}
              style={{ marginBottom: "20px" }}
            />

            {paymentTo === "INVOICE" && clientInvoicesInfo ? (
              <Col lg={24}>
                <Card style={{ margin: "10px 0px" }}>
                  <Form.List name="invoices" initialValue={[{}]}>
                    {(fields, { add, remove }) => (
                      <Row gutter={10} align={"middle"}>
                        {fields.map(({ key, name }, index) => (
                          <NewMoneyReceiptSpecificInvoice
                            fields={fields}
                            key={key}
                            name={name}
                            remove={remove}
                            selectClient={selectClient}
                            paymentTo={paymentTo}
                            index={index}
                            form={form}
                            reason={reason}
                            data={data}
                            disableRemoveIndex={
                              disableInvoiceEdit && disableRemoveInvoiceIndex
                            }
                          />
                        ))}
                        <Col lg={1}>
                          <Button type="primary" onClick={() => add()}>
                            <PlusOutlined />
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Form.List>
                </Card>
              </Col>
            ) : null}
            {/* Specific Invoice */}
            {paymentTo === "TICKET" && clientInvoicesInfo ? (
              <Col lg={24}>
                <Card style={{ margin: "10px 0px" }}>
                  <Form.List name="tickets" initialValue={[{}]}>
                    {(fields, { add, remove }) => (
                      <Row gutter={10} align={"middle"}>
                        {fields.map(({ key, name }, index) => (
                          <NewMoneyReceiptSpecificInvoice
                            fields={fields}
                            key={key}
                            name={name}
                            remove={remove}
                            selectClient={selectClient}
                            paymentTo={paymentTo}
                            index={index}
                            form={form}
                            reason={reason}
                            disableRemoveIndex={
                              disableTicketEdit && disableRemoveTicketIndex
                            }
                          />
                        ))}
                        <Col lg={1}>
                          <Button type="primary" onClick={() => add()}>
                            <PlusOutlined />
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Form.List>
                </Card>
              </Col>
            ) : null}

            <SelectPaymentMethod
              required
              name="receipt_payment_type"
              label="Payment Method "
              onChange={(value: number) => {
                setPayMethodCatId(value);
                setPaymentMethod(value);
              }}
              cheque
              size={12}
            />

            {Number(payMethodCatId) === 4 ? (
              <>
                <FormInputItem
                  label="Cheque No"
                  name="cheque_number"
                  required
                  size={12}
                />
                <DateInput
                  name="cheque_withdraw_date"
                  label="Withdraw Date"
                  required
                  size={12}
                />
                <FormInputItem
                  name={"cheque_bank_name"}
                  label="Bank Name"
                  required
                  size={12}
                  disabled={paymentMethod || reason === "EDIT" ? false : true}
                />
              </>
            ) : (
              <AccountLastBalanceInput
                accountsLastBalance={accounts ? accounts.data : []}
                name="account_id"
                label="Account"
                required
                size={12}
              />
            )}

            {Number(payMethodCatId) === 3 && (
              <>
                <FormInputItem
                  size={12}
                  label="Receipt/Trans No"
                  name="trans_no"
                />

                <FormInputItem
                  size={12}
                  name={"charge_amount"}
                  label="Transaction Charge"
                />
              </>
            )}

            <NumberInput
              size={12}
              label="Amount"
              name={"receipt_total_amount"}
              required
              readOnly={paymentTo === "INVOICE" || paymentTo === "TICKET"}
              min="0"
              maxChar={14}
              minChar={0}
            />

            <SelectEmployee
              required={Number(payMethodCatId) === 1}
              offDropDown
              size={12}
              label="Received By"
              name={"received_by"}
            />
            <NumberInput
              size={12}
              label="Discount"
              name={"receipt_total_discount"}
              min="0"
              maxChar={14}
              minChar={0}
            />

            <FormInput
              size={12}
              label="Manual Money receipt no"
              name={"receipt_money_receipt_no"}
            />
            <TextAreaInput size={12} label="Details" name={"receipt_note"} />
          </Row>

          <Row justify={"end"}>
            <FormButton
              label={
                reason !== "EDIT"
                  ? "Create Money Receipt"
                  : "Update Money Receipt"
              }
              size={12}
              loading={postLoading || editIsLoading}
            />
          </Row>
        </div>
      </Form>
    </ClientAddStyle>
  );
};

export default MoneyReceiptForm;
const ClientAddStyle = styled.div``;
