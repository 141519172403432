import { ArrowLeftOutlined, PrinterOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Row,
  Space,
  Table,
  Typography,
  theme,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { IPermission } from "../../../../common/types/commonTypes";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import { a4sizeStyle } from "../../../Loan_Management/Pages/Loan/ViewLoan";
import { invoiceViewTitleStyle } from "../../../Money_Receipt/Components/MoneyReceiptDesign";
import { useGetSinglePartialRefundQuery } from "../../Api/Endpoints/PartialRefundEndpoints";
import ViewRefundHeader, {
  ViewRefundFooter,
} from "../../Components/ViewRefundHeader";
import { PartialRefundClientUtils } from "../../Utils/PartialRefundUtils/PartialRefundSingleUtils/PartialRefundClientUtils";
import { PartialRefundVendorUtils } from "../../Utils/PartialRefundUtils/PartialRefundSingleUtils/PartialRefundVendorUtils";
import { flexBetween } from "../Airticket/AirTicketRefundCopy";

type Props = {
  permission?: IPermission;
};
const ViewPartialRefund = ({ permission }: Props) => {
  const { id } = useParams();

  const [date, setDate] = useState<string>();
  const [index, setIndex] = useState<number>(0);

  const componentRefClient = useRef(null);
  const refs: React.MutableRefObject<HTMLDivElement[]> = useRef([]);

  const { data: singlePartialRefund, isLoading } =
    useGetSinglePartialRefundQuery(Number(id));

  const partialRefund = singlePartialRefund?.data;

  useEffect(() => {
    if (partialRefund) {
      setDate(dayjs(partialRefund.prfnd_date).format("DD MMM YYYY"));
    }
  }, [partialRefund]);

  const handleClientPrint = useReactToPrint({
    content: () => componentRefClient.current,
    documentTitle: `Refund${date}_$}`,
    pageStyle:
      "@page { size: a4; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 10px !important; } }",
  });

  const handleVendorPrint = useReactToPrint({
    content: () => refs.current[index],
    documentTitle: `Refund${date}_$}`,
    pageStyle:
      "@page { size: a4; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 10px !important; } }",
  });

  const vrefund_amount =
    partialRefund &&
    Number(partialRefund.vendor_refund_info[0].vprfnd_total_amount) -
      Number(partialRefund.vendor_refund_info[0].vprfnd_charge_amount);

  return (
    <div>
      <BreadCrumb
        arrOfOption={["Dashboard", "Refund", "View Partial Refund"]}
      />

      <Space>
        <Link to={"/refund/history/partial"}>
          <Button type="primary">
            <ArrowLeftOutlined />
            Return To Refund List
          </Button>
        </Link>
      </Space>

      {/* CLIENT COPY */}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "100vh",
          padding: "20px 0",
        }}
      >
        <div>
          <div style={flexBetween}>
            <h2 style={{ color: "#18b4e9" }}>CLIENT COPY</h2>

            <Button type="primary" onClick={handleClientPrint}>
              <PrinterOutlined />
              Print
            </Button>
          </div>

          <ConfigProvider
            theme={{
              algorithm: theme.defaultAlgorithm,
            }}
          >
            <Card style={a4sizeStyle}>
              <div ref={componentRefClient}>
                <ViewRefundHeader />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography.Title level={5} style={invoiceViewTitleStyle}>
                    Refund Voucher
                  </Typography.Title>
                  Client Copy
                </div>

                <Row justify={"space-between"} align="middle">
                  <Col
                    style={{
                      color: "#fff",
                      fontFamily: "'Source Sans Pro', sans-serif",
                    }}
                  >
                    <Typography.Text
                      style={{
                        display: "block",
                        fontSize: "13px",
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      <b>Client Name :</b>{" "}
                      {partialRefund ? partialRefund.client_name : ""}
                    </Typography.Text>

                    <Typography.Text
                      style={{
                        display: "block",
                        fontSize: "13px",
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      <b>Refund Invoice No</b>{" "}
                      {partialRefund ? partialRefund.invoice_no : ""}
                    </Typography.Text>
                  </Col>

                  <Col
                    style={{
                      color: "#fff",
                      fontFamily: "'Source Sans Pro', sans-serif",
                    }}
                  >
                    <Typography.Title
                      style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                      level={5}
                    >
                      Refund Information:
                    </Typography.Title>

                    <Typography.Text
                      style={{
                        display: "block",
                        fontSize: "13px",
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      <b>Voucher No :</b>{" "}
                      {partialRefund && partialRefund?.prfnd_vouchar_number}
                    </Typography.Text>

                    <Typography.Text
                      style={{
                        display: "block",
                        fontSize: "13px",
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      <b>Date :</b>{" "}
                      {dayjs(
                        partialRefund ? partialRefund?.prfnd_date : dayjs()
                      ).format("DD MMM YYYY")}
                    </Typography.Text>
                  </Col>
                </Row>

                <Table
                  size="small"
                  className="invoiceBillingTable"
                  rowClassName={"invoiceBillingTd"}
                  bordered
                  columns={PartialRefundClientUtils()}
                  dataSource={partialRefund ? [partialRefund] : []}
                  style={{ marginTop: "10%" }}
                  pagination={false}
                  rowKey={(record: any) => record.rfitem_ticket_no}
                  summary={() => {
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={5}>
                            <Typography style={{ fontWeight: 500 }}>
                              Total Refund Amount
                            </Typography>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={4}>
                            <Typography style={{ fontWeight: 500 }}>
                              {Number(
                                partialRefund
                                  ? partialRefund.prfnd_return_amount
                                  : 0
                              )}
                            </Typography>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />

                <ViewRefundFooter
                  amount={partialRefund && partialRefund?.prfnd_return_amount}
                />
              </div>
            </Card>
          </ConfigProvider>

          {/* VENDOR COPY */}
          {singlePartialRefund?.data &&
            singlePartialRefund?.data.vendor_refund_info.map(
              (item: any, index: number) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      minHeight: "100vh",
                      padding: "20px 0",
                    }}
                  >
                    <div>
                      <div style={flexBetween}>
                        <h2 style={{ color: "#18b4e9" }}>VENDOR COPY</h2>
                        <Button
                          type="primary"
                          onClick={() => {
                            setIndex(index);
                            handleVendorPrint();
                          }}
                        >
                          <PrinterOutlined />
                          Print
                        </Button>
                      </div>

                      <ConfigProvider
                        key={index}
                        theme={{
                          algorithm: theme.defaultAlgorithm,
                        }}
                      >
                        <Card style={a4sizeStyle} key={index}>
                          <div
                            ref={(e: HTMLDivElement) => {
                              refs.current[index] = e;
                            }}
                          >
                            <ViewRefundHeader />

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyItems: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography.Title
                                level={5}
                                style={invoiceViewTitleStyle}
                              >
                                Refund Voucher
                              </Typography.Title>
                              Vendor Copy
                            </div>

                            <Row justify={"space-between"} align="middle">
                              <Col
                                style={{
                                  color: "#fff",
                                  fontFamily: "'Source Sans Pro', sans-serif",
                                }}
                              >
                                <Typography.Text
                                  style={{
                                    display: "block",
                                    fontSize: "13px",
                                    fontFamily: "'Source Sans Pro', sans-serif",
                                  }}
                                >
                                  <b>Vendor Name :</b> {item.vendor_name}
                                </Typography.Text>
                              </Col>

                              <Col
                                style={{
                                  color: "#fff",
                                  fontFamily: "'Source Sans Pro', sans-serif",
                                }}
                              >
                                <Typography.Title
                                  style={{
                                    fontFamily: "'Source Sans Pro', sans-serif",
                                  }}
                                  level={5}
                                >
                                  Refund Information:
                                </Typography.Title>

                                <Typography.Text
                                  style={{
                                    display: "block",
                                    fontSize: "13px",
                                    fontFamily: "'Source Sans Pro', sans-serif",
                                  }}
                                >
                                  <b>Voucher No :</b>{" "}
                                  {partialRefund?.prfnd_vouchar_number}
                                </Typography.Text>

                                <Typography.Text
                                  style={{
                                    display: "block",
                                    fontSize: "13px",
                                    fontFamily: "'Source Sans Pro', sans-serif",
                                  }}
                                >
                                  <b>Date :</b> {date}
                                </Typography.Text>
                              </Col>
                            </Row>

                            <Table
                              size="small"
                              className="invoiceBillingTable"
                              rowClassName={"invoiceBillingTd"}
                              bordered
                              columns={PartialRefundVendorUtils()}
                              dataSource={partialRefund?.vendor_refund_info}
                              style={{ marginTop: "10%" }}
                              pagination={false}
                              rowKey={(record) =>
                                record.vprfnd_vendor_id as number
                              }
                              summary={(record) => {
                                const TotalAmount = record?.reduce(
                                  (total, Value) => {
                                    return (
                                      total +
                                      Number(Value?.vprfnd_return_amount)
                                    );
                                  },
                                  0
                                );
                                // const chargeAmount = record?.reduce(
                                //   (total, Value) => {
                                //     return (
                                //       total +
                                //       Number(Value?.vprfnd_charge_amount)
                                //     );
                                //   },
                                //   0
                                // );

                                return (
                                  <>
                                    <Table.Summary.Row>
                                      <Table.Summary.Cell index={0} colSpan={9}>
                                        <Typography
                                          style={{
                                            fontWeight: 500,
                                          }}
                                        >
                                          Total Refund Amount
                                        </Typography>
                                      </Table.Summary.Cell>
                                      <Table.Summary.Cell index={4}>
                                        <Typography
                                          style={{
                                            fontWeight: 500,
                                          }}
                                        >
                                          {Number(TotalAmount)}
                                        </Typography>
                                      </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                  </>
                                );
                              }}
                            />

                            <ViewRefundFooter amount={vrefund_amount} />
                          </div>
                        </Card>
                      </ConfigProvider>
                    </div>
                  </div>
                );
              }
            )}

          {isLoading && loadingIndicator}
        </div>
      </div>
    </div>
  );
};

export default ViewPartialRefund;
