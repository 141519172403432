import { Alert, Form, message } from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FormButton } from "../../../components/common/FormItem/FormItems";
import BreadCrumb from "../../../components/common/breadCrumb/BreadCrumb";

import { useParams } from "react-router";
import LoadingIndicator from "../../../components/common/spinner/LoadingIndicator";
import { useGetAllAirportQuery } from "../../Configuration/airport/endpoints/airportEndpoints";
import AirticketTicketDetails from "../Components/AgencyWiseCalculation/AirticketTicketDetails";
import AirticketFlightDetails from "../Components/AirticketFlightDetails";
import AirticketHeader from "../Components/AirticketHeader";
import AirticketPaxPassportDetails from "../Components/AirticketPaxPassportDetails";
import EditOverview from "../Components/EditOverview";
import { useGetSimilarAirticketInvoiceQuery } from "../api/endpoints/airticketInvoiceEndpoints";
import { AirTicketInvoiceData } from "../types/invoiceAirTicketTypes";
import { getEditDataToEditableFormat } from "../utils/ts/updateInvoiceAirTicketAndPreview";

type Props = {};

const EditInvoiceAirTicket = ({}: Props) => {
  //  @STATES
  const [invoices, setInvoices] = useState<AirTicketInvoiceData[]>([]);
  const [editIndex, setEditIndex] = useState<number | undefined>();

  const [form] = useForm();
  const getHeightRef = useRef(null);
  const { airticketInvoiceId } = useParams();

  // @EDIT TIME GET INVOICE INFORMATION
  const { data: similarInvoiceData, isLoading: fetchIsLoading } =
    useGetSimilarAirticketInvoiceQuery(airticketInvoiceId);

  // @SET INITIAL VALUES
  useEffect(() => {
    form.setFieldsValue({
      // airticket_discount_type: 'amount',
      airticket_ait_from: "Profit",
      airticket_other_bonus_type: "amount",
      invoice_sales_date: dayjs(),
      airticket_issue_date: dayjs(),
      dailCode: "+88",
    });
  }, []);

  // @HANDLE SUBMIT
  const handleSubmit = (e: any) => {
    // AFTER ADD GET SCREEN TOP
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (invoices.length >= 45) {
      return message.error("Max 45 Items ");
    }

    // GET OLD DATA FOR SET PREVIEWS DATA
    const getOldData = form.getFieldsValue([
      "invoice_no",
      "invoice_sales_man_id",
      "invoice_combclient_id",
      "invoice_agent_id",
    ]);

    // RESET FORM
    form.resetFields();

    // SET DATA TO STATE
    if (!(editIndex || editIndex === 0)) {
      setInvoices((prev) => [...prev, e]);
    } else {
      // @Handle Edit
      setInvoices(
        invoices.map((item, index) => {
          if (index === editIndex) {
            return e;
          }

          return item;
        })
      );
      setEditIndex(undefined);
    }

    // return;

    // SET PREVIEWS DATA
    form.setFieldsValue({
      // airticket_discount_type: 'amount',
      airticket_ait_from: "Profit",
      airticket_other_bonus_type: "amount",
      invoice_sales_date: dayjs(
        similarInvoiceData?.data?.invoice_info.invoice_sales_date
      ),
      airticket_issue_date: dayjs(),
      invoice_no: similarInvoiceData?.data?.invoice_info.invoice_no,
      invoice_sales_man_id: getOldData?.invoice_sales_man_id,
      invoice_combclient_id: getOldData?.invoice_combclient_id,
      invoice_agent_id: getOldData?.invoice_agent_id,
    });
  };

  // @AIRPORT DATA
  const { data: airport } = useGetAllAirportQuery();
  const selectAirport = airport?.data;

  useEffect(() => {
    if (similarInvoiceData?.data && selectAirport) {
      const formatForEdit: AirTicketInvoiceData[] = getEditDataToEditableFormat(
        similarInvoiceData?.data,
        selectAirport
      );

      setInvoices(formatForEdit);
      const invoiceInfo = similarInvoiceData?.data.invoice_info;
      form.setFieldsValue({
        invoice_combclient_id: invoiceInfo.invoice_combclient_id,
        invoice_sales_man_id: invoiceInfo.invoice_sales_man_id,
        invoice_no: invoiceInfo.invoice_no,
        invoice_sales_date: dayjs(invoiceInfo.invoice_sales_date),
        invoice_due_date: invoiceInfo.invoice_due_date
          ? dayjs(invoiceInfo.invoice_due_date)
          : undefined,
        invoice_agent_id: invoiceInfo?.invoice_agent_id || undefined,
      });
    }
  }, [similarInvoiceData, selectAirport]);

  return (
    <ClientsStyle>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <BreadCrumb arrOfOption={["Invoice (Air Ticket)"]} />
      </div>

      {/* EDIT DATA FETCH LOADING */}
      {fetchIsLoading ? <LoadingIndicator /> : ""}

      {/* AIR TICKET DETAILS */}
      <div className="separator">
        <div className="left_side" ref={getHeightRef}>
          <Form
            form={form}
            labelAlign="left"
            layout="vertical"
            onFinish={handleSubmit}
            onFinishFailed={(value) => {
              message.error(
                `Enter all required (${value.errorFields.length}) Fields`
              );
            }}
          >
            {/* INVOICE HEADER */}
            <AirticketHeader form={form} />
            {editIndex || editIndex === 0 ? (
              <>
                <br />
                <Alert
                  message={`Update Active -- ${invoices[editIndex]?.airticket_ticket_no}`}
                />
              </>
            ) : (
              ""
            )}

            <AirticketTicketDetails
              form={form}
              invoices={invoices}
              editIndex={editIndex}
            />

            {/*<AgencyWiseGet_AirticketTicketDetails
              form={form}
              invoices={invoices}
              editIndex={editIndex}
              editmode
            /> */}

            {/* PAX AND PASSPORT DETAILS */}
            <AirticketPaxPassportDetails
              form={form}
              ticketInfo={similarInvoiceData?.data?.ticketInfo}
              formName="pax_passport"
            />

            {/* FLIGHT DETAILS */}
            <AirticketFlightDetails
              form={form}
              ticketInfo={similarInvoiceData?.data?.ticketInfo}
            />
            <FormButton
              label={!(editIndex || editIndex === 0) ? "Add New" : "Update"}
            />
          </Form>
        </div>

        <div className="right_side">
          {/* @OVERVIEW BEFORE POST */}
          <EditOverview
            invoices={invoices}
            form={form}
            setInvoices={setInvoices}
            setEditIndex={setEditIndex}
            edit_data={similarInvoiceData?.data}
            editIndex={editIndex}
            ticketInfo={similarInvoiceData?.data?.ticketInfo}
          />
        </div>
      </div>
    </ClientsStyle>
  );
};
export default EditInvoiceAirTicket;
const ClientsStyle = styled.div`
  .separator {
    display: flex;
    gap: 25px;
  }

  .left_side {
    width: 70%;
  }

  .right_side {
    width: 25%;
    position: fixed;
    right: 10px;
  }

  .separator {
    @media only screen and (max-width: 1207px) {
      flex-direction: column !important;
    }
  }
  .left_side {
    @media only screen and (max-width: 1207px) {
      width: 100% !important;
    }
    @media only screen and (min-width: 1208px) {
      width: 67% !important;
    }
    @media only screen and (min-width: 1400px) {
      width: 68% !important;
    }
    @media only screen and (min-width: 1500px) {
      width: 69% !important;
    }
    @media only screen and (min-width: 1600px) {
      width: 70% !important;
    }
  }
  .right_side {
    @media only screen and (max-width: 1207px) {
      width: 100% !important;
      position: static;
    }
    @media only screen and (max-height: 920px) {
      position: sticky;
    }
  }

  .body_left {
    width: 100%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .body_right {
    width: 100%;
    margin-left: auto;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .commonBox {
    margin-bottom: 1rem;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 0.4rem;
    }
  }
  .invoice_calculation {
    @media only screen and (max-width: 990px) {
      flex-direction: column !important;
    }
  }
  .invoice_calculation_right {
    @media only screen and (max-width: 990px) {
      width: 100% !important;
      margin-left: 0px !important;
    }
  }
  .invoice_calculation_left {
    @media only screen and (max-width: 990px) {
      width: 100% !important;
    }
  }
`;
