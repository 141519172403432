import { AutoComplete, Col, Divider, Form, Input, Select, Space } from "antd";
import { Rule } from "antd/es/form";
import { NamePath } from "antd/es/form/interface";
import React, { CSSProperties, useEffect, useState } from "react";
import { ISelectData, commonProps } from "../../../common/types/commonTypes";
import CountryName from "../../../helpers/CountryName";
import { AgentProfileDataType } from "../../../modules/Client/Agents_Profile/types/agentProfileTypes";
import {
  useGetAllCombClientsQuery,
  useGetClientsQuery,
  useLazyGetClientsBySearchQuery,
} from "../../../modules/Client/Client/api/endpoints/clientEndpoints";
import {
  genderChildren,
  mobileChildren,
} from "../../../modules/Client/Client/utils/utils_tsx/utilAddClient";
import { useLazyGetAllCombBySearchQuery } from "../../../modules/Client/combined_clients/api/endpoints/clientCombineEndpoints";
import { useGetAllAirlinesQuery } from "../../../modules/Configuration/airline/Endpoints/airlineEndpoints";
import {
  useGetAllAirportQuery,
  useGetAllCountriesQuery,
} from "../../../modules/Configuration/airport/endpoints/airportEndpoints";
import { useGetAllClientCategoryQuery } from "../../../modules/Configuration/clientCategory/endpoints/clientCategoryEndpoints";
import { useGetAllEmployeesQuery } from "../../../modules/Configuration/employee/endpoints/employeeEndpoints";
import { useGetAllExpenseHeadQuery } from "../../../modules/Configuration/expenseHead/Endpoints/expenseHeadEndpoints";
import { useGetExpenseSubHeadByIDQuery } from "../../../modules/Configuration/expenseSubHead/Endpoints/expenseSubHeadEndpoints";
import { useGetAllGroupsQuery } from "../../../modules/Configuration/group/endpoints/groupEndpoints";
import { useGetAllMaharamQuery } from "../../../modules/Configuration/mahram/endpoints/maharamEndpoints";
import { useGetAllPassportStatusQuery } from "../../../modules/Configuration/passportStatus/endpoints/passportStatusEndpoints";

import { useAppDispatch } from "../../../app/hooks";
import { useGetAllProductsQuery } from "../../../modules/Configuration/Products/endpoints/productsEndpoints";
import { useGetAllCitiesQuery } from "../../../modules/Configuration/Tour/Accommodations/endpoints/accommodationEndpoints";
import { useGetAllUsersQuery } from "../../../modules/Configuration/Users/endpoints/userEndpoints";
import { useGetAllAgencyQuery } from "../../../modules/Configuration/agency/endpoints/agencyEndpoints";
import InstanceCreateEmployee from "../../../modules/Configuration/employee/modals/InstanceCreateEmployee";
import { employeeDataType } from "../../../modules/Configuration/employee/types/employeeTypes";
import { useGetAllRoomTypesQuery } from "../../../modules/Configuration/roomTypes/endpoints/roomTypesEndpoints";
import { useGetTransportTypeQuery } from "../../../modules/Invoice(Other)/Api/invoiceOtherEndpoints";
import { useSelectPassportQuery } from "../../../modules/Passport_MGT/Api/Endpoints/PassportEndpoints";
import { useGetAllAccountListQuery } from "../../../modules/Reports/AccountsReport/endpoints/accountReportEndpoints";
import {
  useGetAllTicketNoQuery,
  useGetAllVisaListQuery,
} from "../../../modules/Reports/Profit_Loss/endpoints/profitLossEndpoints";
import { useGetAllSubHeadWiseReportQuery } from "../../../modules/Reports/SubHeadWiseExpense/endpoints/SubheadWiseEndpoints";
import { useGetVisaTypeQuery } from "../../../modules/vendor/api/endpoints/advanceEndpoints";
import {
  useGetAllVenAndComSearchQuery,
  useGetBspPeriodQuery,
  useGetProductWiseVendorsAndCombinedQuery,
  useLazyGetAllVendorsQuery,
} from "../../../modules/vendor/api/endpoints/vendorEndpoints";
import {
  useGetOverAllInvoicesFor_V_PaymentQuery,
  useGetOverAllInvoicesFor_V_Payment_editQuery,
  useGetOverAllInvoicesQuery,
} from "../../../modules/vendor/api/endpoints/vendorPaymentEndpoints";

import { useWatch } from "antd/es/form/Form";
import { FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import { useParams } from "react-router";
import {
  Fixed2,
  getAirportByItaCode,
} from "../../../common/utils/common.utils";
import { useGetAllAgentProfileQuery } from "../../../modules/Client/Agents_Profile/api/endpoints/agentProfileEndPoints";
import { IAirport } from "../../../modules/Configuration/airport/types/AirportTypes";
import { useLazyGetTrackingNoByClientIdQuery } from "../../../modules/Hajji Management/API/Endpoints/HajjiManageMentEndspont";
import { ITrackingNoGetAll } from "../../../modules/Hajji Management/Types/HajjiManagement.interface";

import SelectLoading from "../../../common/utils/SelectLoading";
import { ISelectClientDetails } from "../../../modules/IATA_AIR_TICKET/types/invoiceAirTicketTypes";
import {
  useGetClientInvoicesInfoEditQuery,
  useGetClientInvoicesInfoQuery,
} from "../../../modules/Money_Receipt/api/endpoints/moneyReceiptEndpoints";
import {
  useLazyGetRefundableInvoiceQuery,
  useLazyGetRefundableTicketsQuery,
} from "../../../modules/Refund/Api/Endpoints/PartialRefundEndpoints";
import { IPartialRefundInvoice } from "../../../modules/Refund/RefundTypes/RefundTypes";
import { useSelectBspFileListQuery } from "../../../modules/Reports/BSPBillCheck/api/bspBillApisEndpoints";
import AddVendorModal from "../../../modules/vendor/pages/AddVendorModal";
import { IVendorsAndCombined } from "../../../modules/vendor/types/vendor.interfaces";
import CreateAgentModal from "../CreateAgentModal";
import CreateClientModal from "../CreateClientModal";
import CreatePassportModal from "../CreatePassportModal";
import CreateVendorModal from "../CreateVendorModal";

type vendorProps = {
  vendorInfoName?: number;
  ticketInfoName?: number;
  isPercentage?: boolean;
  rules?: Rule[];
  dependencies?: NamePath[];
  selectVendor?: any;
  vendorID?: number;
  manualData?: IVendorsAndCombined[];
  setInstanceVendorSelect?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  searchText?: string | null;
  setFetch?: React.Dispatch<React.SetStateAction<boolean>>;
  vendor_id?: string[];
  showDropdownModal?: boolean;
  setInstantVendor?: React.Dispatch<React.SetStateAction<number | undefined>>;
  setGClientName?: React.Dispatch<React.SetStateAction<undefined>> | undefined;
} & commonProps;

// @FORM ITEM LAVEROCK AND WRAPPER WRAP FULL WIDTH
export const formItemLayout = {
  labelCol: {
    span: 24, // Full width
  },
  wrapperCol: {
    span: 24, // Full width
  },
};

const { Option } = Select;

// CLIENTS AND COMBINED CLIENT

export const SelectClients = ({
  name,
  label,
  size,
  required,
  disabled,
  placeholder,
  onChange,
  setInstanceClientSelect,
  showAll,
  loading,
  rules,
  dependencies,
  offDropDown,
  mdSize,
  smSize,
  form,
  autoFillup,
  allowClear,
  setClientDetails,
  setGClientName,
  xxl,
}: commonProps) => {
  const [search, setSearch] = useState("");

  const {
    isLoading,
    isFetching,
    data: clients,
  } = useGetAllCombClientsQuery(search);

  let timeoutId: NodeJS.Timeout | null = null;

  const handleSearch = (value: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      if (value !== "") setSearch(value);
    }, 400);
  };

  const selectClients = clients?.data;

  const clientsChildren: React.ReactNode[] = [];

  if (selectClients) {
    for (let i = 0; i < selectClients.length; i++) {
      const {
        client_id,
        client_name,
        client_type,
        client_entry_id,
        client_mobile,
      } = selectClients[i];

      clientsChildren.push(
        <Select.Option
          key={
            client_name +
            "  " +
            client_id +
            " " +
            client_entry_id +
            " " +
            client_mobile
          }
          value={`${client_type}-${client_id}`}
        >
          {client_name + ` - (${client_entry_id})`}
        </Select.Option>
      );
    }
  }

  const [click, setClick] = useState<boolean>();
  const allOption = (
    <Select.Option key="all" value="all">
      All
    </Select.Option>
  );

  // @SELECT CLIENT AND AUTO FILUP MOBILE
  const client_id = useWatch(name || [], form);
  useEffect(() => {
    if (autoFillup) {
      const findSelected = selectClients?.find(
        (item) => `${item?.client_type}-${item?.client_id}` === client_id
      );
      form?.setFieldsValue({
        client_mobile: findSelected?.client_mobile || undefined,
      });
    }
  }, [client_id]);

  showAll && clientsChildren.unshift(allOption);

  const handleSelectChange = (value: string) => {
    const id = value?.split("-")[1];

    const selectedClientData = selectClients?.find((item) => {
      return Number(item?.client_id) === Number(id);
    }) as ISelectClientDetails;
    setClientDetails && setClientDetails(selectedClientData);
  };

  return (
    <Col
      xs={24}
      sm={smSize ? smSize : 24}
      md={mdSize ? mdSize : 24}
      lg={size ? size : 8}
      xxl={xxl}
    >
      <Form.Item
        name={name}
        label={label}
        rules={[
          { required: required, message: "Client required" },
          ...(rules || []),
        ]}
        dependencies={dependencies}
      >
        <Select
          onSearch={(value) => handleSearch(value)}
          loading={loading || isLoading || isFetching || false}
          dropdownStyle={{ color: "red" }}
          disabled={disabled}
          placeholder={placeholder ? placeholder : label}
          // popupMatchSelectWidth={200}
          showSearch
          allowClear={allowClear ? allowClear : true}
          style={{ padding: "0", margin: "0", border: "0" }}
          filterOption={(input, option) =>
            (option!.key as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onSelect={(e: string, p: any) => {
            onChange && onChange(e);
            setGClientName && setGClientName(p);
          }}
          onChange={handleSelectChange}
          dropdownRender={
            offDropDown
              ? undefined
              : (menu) => (
                  <>
                    <CreateClientModal
                      setClick={setClick}
                      setInstanceClientSelect={setInstanceClientSelect}
                    />
                    <Divider style={{ margin: "8px 0" }} />
                    {menu}
                  </>
                )
          }
          open={click === true ? false : undefined}
        >
          {clientsChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectClientsOnly = ({
  name,
  label,
  size,
  required,
  disabled,
  placeholder,
  showAll,
  mdSize,
  smSize,
  xsSize,
  onChange,
  offDropDown,
  searchValue,
  xxl,
}: commonProps) => {
  const [fetchClient, { data: clients, isLoading, isFetching }] =
    useLazyGetClientsBySearchQuery();

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue as string);
    } else {
      fetchClient("", true);
    }
  }, [searchValue]);

  let timeoutId: NodeJS.Timeout | null = null;

  const handleSearch = (value: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      fetchClient(value, true);
    }, 300);
  };

  const selectClients = clients?.data;

  const clientsChildren: React.ReactNode[] = [];

  if (selectClients) {
    for (let i = 0; i < selectClients.length; i++) {
      const { client_id, client_name, client_entry_id } = selectClients[i];
      clientsChildren.push(
        <Select.Option key={client_name + "  " + client_id} value={client_id}>
          {client_name + "/" + client_entry_id}
        </Select.Option>
      );
    }
  }

  const [click, setClick] = useState<boolean>();
  const allOption = (
    <Select.Option key="all" value="all">
      ALL
    </Select.Option>
  );

  showAll && clientsChildren.unshift(allOption);
  return (
    <Col
      xs={xsSize ? xsSize : 24}
      sm={smSize ? smSize : 24}
      md={mdSize ? mdSize : 24}
      lg={size ? size : 8}
      xxl={xxl ? xxl : size}
    >
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required, message: "Client required" }]}
      >
        <Select
          loading={isLoading || isFetching}
          disabled={disabled}
          placeholder={placeholder ? placeholder : label}
          onSearch={handleSearch}
          showSearch
          allowClear
          style={{ padding: "0", margin: "0", border: "0" }}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onSelect={(e: string) => {
            onChange && onChange(e);
          }}
          dropdownRender={
            offDropDown
              ? undefined
              : (menu) => (
                  <>
                    <CreateClientModal setClick={setClick} />
                    <Divider style={{ margin: "8px 0" }} />
                    {menu}
                  </>
                )
          }
          open={click === true ? false : undefined}
        >
          {clientsChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};
export const SelectCombined = ({
  name,
  label,
  size,
  required,
  disabled,
  placeholder,
  setCombId,
  onChange,
  showAll,
  offDropDown,
  mdSize,
}: commonProps) => {
  const [fetchCombine, { data: combined }] = useLazyGetAllCombBySearchQuery();

  useEffect(() => {
    fetchCombine("");
  }, []);

  let timeoutId: NodeJS.Timeout | null = null;

  const handleSearch = (value: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      fetchCombine(value);
    }, 300);
  };

  const selectcombined = combined?.data;

  const combinedChildren: React.ReactNode[] = [];

  if (selectcombined) {
    for (let i = 0; i < selectcombined.length; i++) {
      const { combine_id, combine_name, combine_entry_id } = selectcombined[i];
      combinedChildren.push(
        <Select.Option
          key={combine_name + "  " + combine_id}
          value={combine_id}
        >
          {combine_name}
        </Select.Option>
      );
    }
  }

  const [click, setClick] = useState<boolean>();
  const allOption = (
    <Select.Option key="all" value="all">
      All
    </Select.Option>
  );

  showAll && combinedChildren.unshift(allOption);
  return (
    <Col xs={24} sm={24} md={mdSize || 24} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required, message: "Client required" }]}
      >
        <Select
          disabled={disabled}
          allowClear
          placeholder={placeholder ? placeholder : label}
          showSearch
          onSearch={handleSearch}
          onChange={(e) => setCombId && setCombId(e)}
          style={{ padding: "0", margin: "0", border: "0" }}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onSelect={(e: string) => {
            onChange && onChange(e);
          }}
          dropdownRender={
            offDropDown
              ? undefined
              : (menu) => (
                  <>
                    <CreateClientModal setClick={setClick} />
                    <Divider style={{ margin: "8px 0" }} />
                    {menu}
                  </>
                )
          }
          open={click === true ? false : undefined}
        >
          {combinedChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

// SELECT VENDORS AND COMBINE VENDOR

export const SelectVendors = ({
  name,
  label,
  size,
  mdSize,
  smSize,
  required,
  small,
  onChange,
  ticketInfoName,
  vendorInfoName,
  isPercentage,
  rules,
  disabled,
  selectVendor,
  dependencies,
  manualData,
  setInstanceVendorSelect,
  showAll,
  mode,
  offDropDown,
  searchText,
  setFetch,
  hidden,
}: vendorProps) => {
  const vendorsChildren: React.ReactNode[] = [];

  const [search, setSearch] = useState("");
  const { data: vendors, isSuccess } = useGetAllVenAndComSearchQuery(search);

  const selectVendors = vendors?.data;

  let timeoutId: NodeJS.Timeout | null = null;

  const handleSearch = (value: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      if (search !== "") setSearch(value);
    }, 300);
  };

  useEffect(() => {
    if (setFetch) if (isSuccess) setFetch(true);
  }, [isSuccess]);

  // THIS FOR ALL VENDORS
  if (selectVendors && !manualData) {
    for (let i = 0; i < selectVendors.length; i++) {
      vendorsChildren.push(
        <Select.Option
          key={
            selectVendors[i]?.vendor_name +
            "  " +
            selectVendors[i].vendor_id +
            " " +
            selectVendors[i]?.vendor_mobile
          }
          value={
            selectVendors[i]?.vendor_type + "-" + selectVendors[i].vendor_id
          }
        >
          {`${selectVendors[i]?.vendor_name} ${
            isPercentage
              ? ` [${selectVendors[i].vproduct_commission_rate || 0}%]`
              : ""
          }`}
        </Select.Option>
      );
    }
  } else {
    // THIS FOR SPECIFIC/MANUAL VENDORS
    if (manualData?.length)
      for (let i = 0; i < manualData.length; i++) {
        vendorsChildren.push(
          <Select.Option
            key={
              manualData[i].vendor_name +
              "  " +
              manualData[i].vendor_id +
              " " +
              manualData[i]?.vendor_mobile
            }
            value={manualData[i].vendor_type + "-" + manualData[i].vendor_id}
          >
            {`${manualData[i].vendor_name} ${
              isPercentage
                ? ` [${manualData[i].vproduct_commission_rate || 0}%]`
                : ""
            }`}
          </Select.Option>
        );
      }
  }

  const [click, setClick] = useState<boolean>();
  const allOption = (
    <Select.Option key="all" value="all">
      ALL
    </Select.Option>
  );
  showAll && vendorsChildren.unshift(allOption);

  return (
    <Col
      xs={24}
      sm={smSize ? smSize : 12}
      md={mdSize ? mdSize : 8}
      lg={size ? size : 8}
      hidden={hidden || false}
    >
      <Form.Item
        dependencies={dependencies}
        name={name}
        label={label}
        required={false || required}
        rules={[
          ({ getFieldValue }) => ({
            required: required || false,
            message: `${label} is required!`,
          }),
          ({ getFieldValue }) => ({
            validator(_, value) {
              const vendorInfo: { itnrvendor_vendor_id: number | undefined }[] =
                getFieldValue([
                  "ticket_info",
                  Number(ticketInfoName),
                  "vendors",
                ]);

              if (
                vendorInfoName &&
                vendorInfoName > 0 &&
                vendorInfo.find(
                  (item, index) =>
                    index !== vendorInfoName &&
                    item !== undefined &&
                    item.itnrvendor_vendor_id === value
                )?.itnrvendor_vendor_id === value
              ) {
                return Promise.reject();
              } else {
                return Promise.resolve();
              }
            },
            message: "Cannot Select Duplicate Vendor!!",
          }),
          ...(rules || []),
        ]}
      >
        <Select
          onSelect={selectVendor}
          onChange={(e: string) => {
            onChange && onChange(e);
          }}
          onSearch={(value) => handleSearch(value)}
          size={small ? "small" : "middle"}
          allowClear
          placeholder={"Select Vendor"}
          showSearch
          disabled={disabled || false}
          filterOption={(input, option) =>
            (option!.key as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          mode={mode}
          dropdownRender={
            offDropDown
              ? undefined
              : (menu) => (
                  <>
                    <CreateVendorModal
                      setClick={setClick}
                      setInstanceVendorSelect={setInstanceVendorSelect}
                    />
                    <Divider style={{ margin: "8px 0" }} />
                    {menu}
                  </>
                )
          }
          open={click === true ? false : undefined}
        >
          {vendorsChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectInvoice = ({
  name,
  label,
  size,
  required,
  small,
  onChange,
  rules,
  disabled,
  dependencies,
}: commonProps) => {
  const { data: invoices } = useGetOverAllInvoicesQuery();
  const invoicesAll = invoices?.data;
  const invoiceAllChildren: React.ReactNode[] = [];

  if (invoicesAll) {
    for (let i = 0; i < invoicesAll.length; i++) {
      invoiceAllChildren.push(
        <Select.Option
          key={invoicesAll[i]?.invoice_no}
          value={invoicesAll[i]?.invoice_id}
        >
          {`${invoicesAll[i]?.invoice_no}`}
        </Select.Option>
      );
    }
  }
  return (
    <Col xs={24} sm={12} md={8} lg={size ? size : 8}>
      <Form.Item
        dependencies={dependencies}
        name={name}
        label={label}
        required={false || required}
        rules={[
          ({ getFieldValue }) => ({
            required: required || false,
            message: `${label} is required!`,
          }),
          ...(rules || []),
        ]}
      >
        <Select
          onChange={(e: string) => {
            onChange && onChange(e);
          }}
          size={small ? "small" : "middle"}
          allowClear
          placeholder={"Select Invoice"}
          showSearch
          disabled={disabled || false}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {invoiceAllChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectInvoiceFor_V_Payment = ({
  name,
  label,
  size,
  required,
  small,
  onChange,
  rules,
  disabled,
  dependencies,
}: commonProps) => {
  const { data: invoices } = useGetOverAllInvoicesFor_V_PaymentQuery();
  const invoicesAll = invoices?.data;
  const invoiceAllChildren: React.ReactNode[] = [];

  if (invoicesAll) {
    for (let i = 0; i < invoicesAll.length; i++) {
      invoiceAllChildren.push(
        <Select.Option
          key={invoicesAll[i]?.invoice_no}
          value={invoicesAll[i]?.invoice_id}
        >
          {`${invoicesAll[i]?.invoice_no}`}
        </Select.Option>
      );
    }
  }
  return (
    <Col xs={24} sm={12} md={8} lg={size ? size : 8}>
      <Form.Item
        dependencies={dependencies}
        name={name}
        label={label}
        required={false || required}
        rules={[
          ({ getFieldValue }) => ({
            required: required || false,
            message: `${label} is required!`,
          }),
          ...(rules || []),
        ]}
      >
        <Select
          onChange={(e: string) => {
            onChange && onChange(e);
          }}
          size={small ? "small" : "middle"}
          allowClear
          placeholder={"Select Invoice"}
          showSearch
          disabled={disabled || false}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {invoiceAllChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectInvoiceFor_V_Payment_Edit = ({
  name,
  label,
  size,
  required,
  small,
  onChange,
  rules,
  disabled,
  dependencies,
}: commonProps) => {
  const { data: invoices } = useGetOverAllInvoicesFor_V_Payment_editQuery();
  const invoicesAll = invoices?.data;
  const invoiceAllChildren: React.ReactNode[] = [];

  if (invoicesAll) {
    for (let i = 0; i < invoicesAll.length; i++) {
      invoiceAllChildren.push(
        <Select.Option
          key={invoicesAll[i]?.invoice_no}
          value={invoicesAll[i]?.invoice_id}
        >
          {`${invoicesAll[i]?.invoice_no}`}
        </Select.Option>
      );
    }
  }
  return (
    <Col xs={24} sm={12} md={8} lg={size ? size : 8}>
      <Form.Item
        dependencies={dependencies}
        name={name}
        label={label}
        required={false || required}
        rules={[
          ({ getFieldValue }) => ({
            required: required || false,
            message: `${label} is required!`,
          }),
          ...(rules || []),
        ]}
      >
        <Select
          onChange={(e: string) => {
            onChange && onChange(e);
          }}
          size={small ? "small" : "middle"}
          allowClear
          placeholder={"Select Invoice"}
          showSearch
          disabled={disabled || false}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {invoiceAllChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectProductWiseVendors = ({
  name,
  label,
  size,
  required,
  small,
  onChange,
  ticketInfoName,
  vendorInfoName,
  isPercentage,
  rules,
  disabled,
  selectVendor,
  dependencies,
  manualData,
  productId,
  setInstanceVendorSelect,
}: vendorProps & { productId: number }) => {
  const { data: vendors } = useGetProductWiseVendorsAndCombinedQuery(productId);
  const selectVendors = vendors?.data;
  const vendorsChildren: React.ReactNode[] = [];
  const [click, setClick] = useState<boolean>();
  // THIS FOR ALL VENDORS
  if (selectVendors && !manualData) {
    for (let i = 0; i < selectVendors.length; i++) {
      vendorsChildren.push(
        <Select.Option
          key={selectVendors[i].vendor_name + "  " + selectVendors[i].vendor_id}
          value={
            selectVendors[i].vendor_type + "-" + selectVendors[i].vendor_id
          }
        >
          {`${selectVendors[i].vendor_name} ${
            isPercentage ? ` [${selectVendors[i]?.commission_rate || 0}%]` : ""
          }`}
        </Select.Option>
      );
    }
  }

  return (
    <Col xs={24} sm={12} md={8} lg={size ? size : 8}>
      <Form.Item
        dependencies={dependencies}
        name={name}
        label={label}
        required={false || required}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              const vendorInfo: { itnrvendor_vendor_id: number | undefined }[] =
                getFieldValue([
                  "ticket_info",
                  Number(ticketInfoName),
                  "vendors",
                ]);

              if (
                vendorInfoName &&
                vendorInfoName > 0 &&
                vendorInfo.find(
                  (item, index) =>
                    index !== vendorInfoName &&
                    item !== undefined &&
                    item.itnrvendor_vendor_id === value
                )?.itnrvendor_vendor_id === value
              ) {
                return Promise.reject();
              } else {
                return Promise.resolve();
              }
            },
            message: "Cannot Select Duplicate Vendor!!",
          }),
          ...(rules || []),
        ]}
      >
        <Select
          onSelect={selectVendor}
          onChange={(e: string) => {
            onChange && onChange(e);
          }}
          size={small ? "small" : "middle"}
          allowClear
          placeholder={"Select Vendor"}
          showSearch
          disabled={disabled}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          dropdownRender={(menu) => (
            <>
              <CreateVendorModal
                setClick={setClick}
                setInstanceVendorSelect={setInstanceVendorSelect}
              />
              <Divider style={{ margin: "8px 0" }} />
              {menu}
            </>
          )}
          open={click === true ? false : undefined}
        >
          {vendorsChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectVendorsOnly = ({
  name,
  label,
  size,
  required,
  small,
  onChange,
  ticketInfoName,
  vendorInfoName,
  vendorID,
  xsSize,
  smSize,
  mdSize,
  disabled,
  showAll,
  showDropdownModal,
  setInstantVendor,
  searchText,
}: vendorProps) => {
  const [search, setSearch] = useState("");
  const [fetchVendor, { data: vendors, isLoading, isFetching }] =
    useLazyGetAllVendorsQuery();

  useEffect(() => {
    fetchVendor(searchText ? searchText : "");
  }, []);

  let timeoutId: NodeJS.Timeout | null = null;

  const handleSearch = (value: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      fetchVendor(value);
    }, 300);
  };

  const selectVendors = vendors?.data;

  const vendorsChildren: React.ReactNode[] = [];

  if (selectVendors) {
    for (let i = 0; i < selectVendors.length; i++) {
      vendorsChildren.push(
        <Select.Option
          key={selectVendors[i].vendor_name + "  " + selectVendors[i].vendor_id}
          value={selectVendors[i].vendor_id}
        >
          {selectVendors[i].vendor_name}
        </Select.Option>
      );
    }
  }
  const allOption = (
    <Select.Option key="all" value="all">
      All
    </Select.Option>
  );

  showAll && vendorsChildren.unshift(allOption);
  const [click, setClick] = useState<boolean>();
  return (
    <Col
      xs={24}
      sm={smSize ? smSize : 12}
      md={mdSize ? mdSize : 8}
      lg={size ? size : 8}
    >
      <Form.Item
        name={name}
        label={label}
        required={false || required}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              const vendorInfo: { itnrvendor_vendor_id: number | undefined }[] =
                getFieldValue([
                  "ticket_info",
                  Number(ticketInfoName),
                  "vendors",
                ]);

              if (
                vendorInfoName &&
                vendorInfoName > 0 &&
                vendorInfo.find(
                  (item, index) =>
                    index !== vendorInfoName &&
                    item !== undefined &&
                    item.itnrvendor_vendor_id === value
                )?.itnrvendor_vendor_id === value
              ) {
                return Promise.reject();
              } else {
                return Promise.resolve();
              }
            },
            message: "Cannot Select Duplicate Vendor!!",
          }),
        ]}
      >
        <Select
          onChange={(e: string) => {
            onChange && onChange(Number(e?.split("-")[1]));
          }}
          onSearch={(value) => handleSearch(value)}
          size={small ? "small" : "middle"}
          allowClear
          placeholder={"Select Vendor"}
          showSearch
          notFoundContent={
            <SelectLoading isFetching={isFetching} isLoading={isLoading} />
          }
          disabled={vendorID ? true : false}
          filterOption={(input, option) => {
            console.log(input, option);

            return (option!.key as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase());
          }}
          // filterOption={false}
          open={click === true ? false : undefined}
          dropdownRender={
            showDropdownModal
              ? (menu) => (
                  <>
                    <AddVendorModal
                      setClick={setClick}
                      setInstantVendor={setInstantVendor}
                    />
                    <Divider style={{ margin: "8px 0" }} />
                    {menu}
                  </>
                )
              : undefined
          }
        >
          {vendorsChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectTicketNo = ({
  name,
  label,
  size,
  required,
  disabled,
  onChange,
  showAll,
  placeholder,
}: commonProps) => {
  const { data: ticket } = useGetAllTicketNoQuery();
  const selectTicket = ticket?.data;

  const clientsChildren: React.ReactNode[] = [];

  const allOption = (
    <Select.Option key="all" value="all">
      ALL
    </Select.Option>
  );
  showAll && clientsChildren.unshift(allOption);

  if (selectTicket) {
    for (let i = 0; i < selectTicket.length; i++) {
      clientsChildren.push(
        <Select.Option
          key={selectTicket[i].ticket_id + "  " + selectTicket[i].ticket_no}
          value={selectTicket[i].ticket_id}
        >
          {`${selectTicket[i].ticket_no}`}
        </Select.Option>
      );
    }
  }
  return (
    <Col span={6} xs={24} sm={24} md={24} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required, message: `${label} is required` }]}
      >
        <Select
          disabled={disabled}
          placeholder={placeholder || "Select Ticket"}
          showSearch
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onSelect={(e: number) => {
            onChange && onChange(e);
          }}
          allowClear
        >
          {clientsChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectPassportStatus = ({
  name,
  label,
  size,
  required,
  disabled,
  showAll,
}: commonProps) => {
  const { data: passportStatus } = useGetAllPassportStatusQuery();
  const selectPassportStatus = passportStatus?.data;
  const passportStatusChildren: React.ReactNode[] = [];
  if (selectPassportStatus) {
    for (let i = 0; i < selectPassportStatus.length; i++) {
      passportStatusChildren.push(
        <Select.Option
          key={
            selectPassportStatus[i].pstatus_name +
            "  " +
            selectPassportStatus[i].pstatus_id
          }
          value={selectPassportStatus[i].pstatus_id}
        >
          {`${selectPassportStatus[i].pstatus_name} `}
        </Select.Option>
      );
    }
  }

  const allOption = (
    <Select.Option key="all" value="all">
      All
    </Select.Option>
  );

  showAll && passportStatusChildren.unshift(allOption);
  return (
    <Col span={6} xs={24} sm={24} md={24} lg={size ? size : 6}>
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required, message: "Select Status is required" }]}
      >
        <Select
          disabled={disabled}
          placeholder={"Select Passport Status"}
          showSearch
          allowClear
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {passportStatusChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

// SELECT PASSPORT

type passportProps = {
  setPassPortIds?: React.Dispatch<React.SetStateAction<number>>;
  setInstancPassportSelect?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  instancPassportSelect?: any;
} & commonProps;

export const SelectPassport = ({
  name,
  label,
  size,
  required,
  disabled,
  setPassPortIds,
  setInstancPassportSelect,
  offDropDown,
  showAll,
  onClear,
  instancPassportSelect,
}: passportProps) => {
  const [search, setSearch] = useState("");

  const { data: passport, isLoading } = useSelectPassportQuery(search);

  let timeoutId: NodeJS.Timeout | null = null;

  const handleSearch = (value: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      if (value !== "") {
        setSearch(value);
      }
    }, 300);
  };

  const selectPassport = passport?.data;

  const passportChildren: React.ReactNode[] = [];

  if (selectPassport) {
    for (let i = 0; i < selectPassport.length; i++) {
      passportChildren.push(
        <Select.Option
          key={
            selectPassport[i].passport_passport_no +
            "  " +
            selectPassport[i].passport_id
          }
          value={selectPassport[i].passport_id}
        >
          {selectPassport[i].passport_passport_no}
        </Select.Option>
      );
    }
  }
  const [click, setClick] = useState<boolean>();

  const allOption = (
    <Select.Option key="all" value="all">
      All
    </Select.Option>
  );
  showAll && passportChildren.unshift(allOption);

  return (
    <Col span={6} xs={24} sm={24} md={24} lg={size ? size : 3}>
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required, message: "Select Passport is required" }]}
      >
        <Select
          loading={isLoading}
          onClear={onClear}
          onChange={(E) => setPassPortIds && setPassPortIds(E)}
          onSearch={handleSearch}
          disabled={disabled}
          placeholder={"Select Passport"}
          showSearch
          allowClear
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              ?.toLowerCase()
              ?.includes(input?.toLowerCase())
          }
          open={click === true ? false : undefined}
          dropdownRender={
            offDropDown
              ? undefined
              : (menu) => (
                  <>
                    <CreatePassportModal
                      setClick={setClick}
                      setInstancPassportSelect={setInstancPassportSelect}
                    />
                    <Divider style={{ margin: "8px 0" }} />
                    {menu}
                  </>
                )
          }
        >
          {passportChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

// SELECT SALES BY

export const SelectEmployee = ({
  name,
  label,
  size,
  required,
  showAll,
  setInstanceSalesEmpSelect,
  offDropDown,
  xxl,
}: commonProps) => {
  const { data: employees } = useGetAllEmployeesQuery();

  const selectEmploye = employees?.data;
  const employeeChildrens: React.ReactNode[] = [];
  if (selectEmploye) {
    for (let i = 0; i < selectEmploye.length; i++) {
      employeeChildrens.push(
        <Select.Option
          title=" "
          key={
            selectEmploye[i].employee_id +
            " " +
            selectEmploye[i].employee_birth_date
          }
          value={selectEmploye[i].employee_id}
        >
          {selectEmploye[i].employee_full_name}
        </Select.Option>
      );
    }
  }

  const allOption = (
    <Select.Option key="all" value="all">
      All
    </Select.Option>
  );

  showAll && employeeChildrens.unshift(allOption);
  const [click, setClick] = useState<boolean>();
  const [editInfo, setEditInfo] = useState<employeeDataType | null>(null);
  const dispatch = useAppDispatch();
  return (
    <Col span={6} xs={24} sm={12} md={8} lg={size ? size : 8} xxl={xxl}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
        ]}
      >
        <Select
          placeholder={"Select employee"}
          showSearch
          allowClear
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          dropdownRender={
            offDropDown
              ? undefined
              : (menu) => (
                  <>
                    <InstanceCreateEmployee
                      setClick={setClick}
                      setInstanceSalesEmpSelect={setInstanceSalesEmpSelect}
                    />
                    <Divider style={{ margin: "8px 0" }} />
                    {menu}
                  </>
                )
          }
          open={click === true ? false : undefined}
        >
          {employeeChildrens}
        </Select>
      </Form.Item>
    </Col>
  );
};

// SELECT EXPENSE HEAD

type SelectExpenseProps = {
  setSubHeadId?:
    | React.Dispatch<React.SetStateAction<Number | undefined>>
    | undefined;
  headID?: number;
  setHeadID?: (arg: number) => void;
} & commonProps;

export const SelectExpenseHead = ({
  name,
  label,
  size,
  placeholder,
  required,
  setHeadID,
  mdSize,
  smSize,
  showAll,
}: SelectExpenseProps) => {
  const { data: headExpense } = useGetAllExpenseHeadQuery();

  const selectExpense = headExpense?.data;

  const headExpenseChildrens: React.ReactNode[] = [];

  if (selectExpense) {
    for (let i = 0; i < selectExpense.length; i++) {
      headExpenseChildrens.push(
        <Select.Option
          key={selectExpense[i].head_name + " " + selectExpense[i].head_id}
          value={selectExpense[i].head_id}
        >
          {`${selectExpense[i].head_name}`}
        </Select.Option>
      );
    }
  }
  const allOption = (
    <Select.Option key="all" value="all">
      All
    </Select.Option>
  );

  showAll && headExpenseChildrens.unshift(allOption);
  return (
    <Col
      span={6}
      xs={24}
      sm={smSize ? smSize : 24}
      md={mdSize ? mdSize : 24}
      lg={size ? size : 8}
    >
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required, message: "Product is required" }]}
      >
        <Select
          placeholder={placeholder || "Select sales by"}
          showSearch
          allowClear
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onSelect={(e: number) => {
            setHeadID && setHeadID(e);
          }}
        >
          {headExpenseChildrens}
        </Select>
      </Form.Item>
    </Col>
  );
};

// SELECT EXPENSE SUB HEAD

export const SelectExpenseSubHead = ({
  name,
  label,
  size,
  placeholder,
  required,
  headID,
  setSubHeadId,
}: SelectExpenseProps) => {
  const { data: subHeadExpense } = useGetExpenseSubHeadByIDQuery(
    headID as number
  );
  const { data: allSubHead } = useGetAllSubHeadWiseReportQuery();
  const SelectClientCategory = allSubHead?.data;
  const subHeadExpenseChildrens: React.ReactNode[] = [];
  if (SelectClientCategory) {
    for (let i = 0; i < SelectClientCategory.length; i++) {
      subHeadExpenseChildrens.push(
        <Select.Option
          key={
            SelectClientCategory[i].subhead_name +
            " " +
            SelectClientCategory[i].subhead_id
          }
          value={SelectClientCategory[i].subhead_id}
        >
          {`${SelectClientCategory[i].subhead_name}`}
        </Select.Option>
      );
    }
  }
  return (
    <Col span={6} xs={24} sm={24} md={24} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required, message: "SubHead Expense is required" }]}
      >
        <Select
          onChange={(e) => setSubHeadId && setSubHeadId(e)}
          placeholder={placeholder || "Select sales by"}
          showSearch
          allowClear
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {subHeadExpenseChildrens}
        </Select>
      </Form.Item>
    </Col>
  );
};
// SELECT EXPENSE SUB HEAD

export const SelectClientCategory = ({
  name,
  label,
  size,
  mdSize,
  smSize,
  placeholder,
  required,
  headID,
  showAll,
  setSubHeadId,
  xxl,
}: SelectExpenseProps) => {
  const { data: clientCategory } = useGetAllClientCategoryQuery();

  const SelectClientCategory = clientCategory?.data;
  const clientCategoryChildrens: React.ReactNode[] = [];
  if (SelectClientCategory) {
    for (let i = 0; i < SelectClientCategory.length; i++) {
      clientCategoryChildrens.push(
        <Select.Option
          key={
            SelectClientCategory[i].category_title +
            " " +
            SelectClientCategory[i].category_id
          }
          value={SelectClientCategory[i].category_id}
        >
          {`${SelectClientCategory[i].category_title}`}
        </Select.Option>
      );
    }
  }
  const allOption = (
    <Select.Option key="all" value="all">
      ALL
    </Select.Option>
  );

  showAll && clientCategoryChildrens.unshift(allOption);
  return (
    <Col
      span={6}
      xs={24}
      sm={smSize ? smSize : 24}
      md={mdSize ? mdSize : 24}
      lg={size ? size : 8}
      xxl={xxl}
    >
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required, message: "SubHead Expense is required" }]}
      >
        <Select
          onChange={(e) => setSubHeadId && setSubHeadId(e)}
          placeholder={placeholder || "Select Client Category"}
          showSearch
          allowClear
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {clientCategoryChildrens}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectUsers = ({
  name,
  label,
  size,
  placeholder,
  required,
  showAll,
  onSelect,
}: commonProps) => {
  const { data: users } = useGetAllUsersQuery();

  const SelectUsers = users?.data;
  const userChildrens: React.ReactNode[] = [];

  if (SelectUsers) {
    for (let i = 0; i < SelectUsers.length; i++) {
      userChildrens.push(
        <Select.Option
          key={SelectUsers[i].user_full_name + " " + SelectUsers[i].user_id}
          value={SelectUsers[i].user_id}
        >
          {`${SelectUsers[i].user_full_name}`}
        </Select.Option>
      );
    }
  }

  const allOption = (
    <Select.Option key="all" value="all">
      ALL
    </Select.Option>
  );

  showAll && userChildrens?.unshift(allOption);

  return (
    <Col span={6} xs={24} sm={24} md={24} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required, message: "Users is required" }]}
      >
        <Select
          allowClear={true}
          placeholder={placeholder || "Select User"}
          onSelect={onSelect}
          showSearch
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {userChildrens}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectProduct = ({
  name,
  label,
  size,
  placeholder,
  required,
  defaultValue,
  disabled,
  showAll,
  setCombId,
  setProductId,
  xxl,
}: commonProps) => {
  const { data: products } = useGetAllProductsQuery();
  const selectProduct = products?.data;

  const productChildrens: React.ReactNode[] = [];
  if (selectProduct) {
    for (let i = 0; i < selectProduct.length; i++) {
      productChildrens.push(
        <Select.Option
          key={
            selectProduct[i].product_name + " " + selectProduct[i].product_id
          }
          value={selectProduct[i].product_id}
        >
          {selectProduct[i].product_name}
        </Select.Option>
      );
    }
  }

  const allOption = (
    <Select.Option key="all" value="all">
      All
    </Select.Option>
  );

  showAll && productChildrens.unshift(allOption);

  return (
    <Col xs={24} sm={12} md={8} lg={size ? size : 8} xxl={xxl}>
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required, message: "Product is required" }]}
      >
        <Select
          onChange={(e) => setProductId && setProductId(Number(e))}
          placeholder={placeholder || "Select sales by"}
          showSearch
          allowClear
          disabled={disabled}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          defaultValue={defaultValue}
        >
          {productChildrens}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectCountry = ({
  name,
  label,
  size,
  mdSize,
  smSize,
  required,
  initialValue,
  onChange,
  showAll,
  disabled,
}: commonProps) => {
  const { data: countries } = useGetAllCountriesQuery();
  const selectCountry = countries?.data;
  const countryChildrens: React.ReactNode[] = [];

  if (selectCountry) {
    for (let i = 0; i < selectCountry.length; i++) {
      countryChildrens.push(
        <Select.Option
          key={
            selectCountry[i].country_id +
            " " +
            selectCountry[i].country_id +
            " " +
            selectCountry[i].country_iso3 +
            " " +
            selectCountry[i].country_phonecode
          }
          value={selectCountry[i].country_id}
        >
          {selectCountry[i].country_name}
        </Select.Option>
      );
    }
  }
  const allOption = (
    <Select.Option key="all" value="all">
      ALL
    </Select.Option>
  );

  showAll && countryChildrens.unshift(allOption);
  return (
    <Col
      xs={24}
      sm={smSize ? smSize : 12}
      md={mdSize ? mdSize : 8}
      lg={size ? size : 6}
    >
      <Form.Item
        initialValue={initialValue}
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
        ]}
      >
        <Select
          onChange={onChange}
          placeholder={"Select Country"}
          showSearch
          allowClear
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          disabled={disabled}
        >
          {countryChildrens}
        </Select>
      </Form.Item>
    </Col>
  );
};

// SELECT CITY

type SelectCityProp = {
  getCityByCId: number | undefined;
} & commonProps;

export const SelectCity = ({
  name,
  label,
  size,
  mdSize,
  smSize,
  required,
  getCityByCId,
}: SelectCityProp) => {
  const { data: cities } = useGetAllCitiesQuery(Number(getCityByCId));

  const selectCountry = cities?.data;
  const countryChildrens: React.ReactNode[] = [];
  if (selectCountry) {
    for (let i = 0; i < selectCountry.length; i++) {
      countryChildrens.push(
        <Select.Option
          key={selectCountry[i].city_name + " " + selectCountry[i].city_id}
          value={selectCountry[i].city_id}
        >
          {selectCountry[i].city_name}
        </Select.Option>
      );
    }
  }
  return (
    <Col
      span={6}
      xs={24}
      sm={smSize ? smSize : 24}
      md={mdSize ? mdSize : 24}
      lg={size ? size : 8}
    >
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
        ]}
      >
        <Select
          placeholder={"Select City"}
          showSearch
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          allowClear
        >
          {countryChildrens}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectRoomType = ({
  name,
  label,
  size,
  required,
  disabled,
}: commonProps) => {
  const { data: roomType } = useGetAllRoomTypesQuery();

  const selectCountry = roomType?.data;
  const countryChildrens: React.ReactNode[] = [];
  if (selectCountry) {
    for (let i = 0; i < selectCountry.length; i++) {
      countryChildrens.push(
        <Select.Option
          key={selectCountry[i].rtype_name + " " + selectCountry[i].rtype_id}
          value={selectCountry[i].rtype_id}
        >
          {selectCountry[i].rtype_name}
        </Select.Option>
      );
    }
  }
  return (
    <Col xs={24} sm={12} md={8} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
        ]}
      >
        <Select
          disabled={disabled}
          placeholder={"Select Room"}
          showSearch
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          allowClear
        >
          {countryChildrens}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectVisaType = ({
  name,
  label,
  size,
  required,
  mdSize,
  smSize,
  disabled,
}: commonProps) => {
  const { data: visaType } = useGetVisaTypeQuery();
  const selectVisaType = visaType?.data;
  const visaTypeChildrens: React.ReactNode[] = [];
  if (selectVisaType) {
    for (let i = 0; i < selectVisaType.length; i++) {
      visaTypeChildrens.push(
        <Select.Option
          key={selectVisaType[i].type_name}
          value={selectVisaType[i].type_id}
        >
          {selectVisaType[i].type_name}
        </Select.Option>
      );
    }
  }
  return (
    <Col
      span={6}
      xs={24}
      sm={smSize ? smSize : 24}
      md={mdSize ? mdSize : 24}
      lg={size ? size : 8}
    >
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
        ]}
      >
        <Select
          placeholder={"Select Visa Type"}
          showSearch
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          allowClear
          disabled={disabled}
        >
          {visaTypeChildrens}
        </Select>
      </Form.Item>
    </Col>
  );
};

interface SelectAirporI {
  form: FormInstance<any>;
  fullNamePath: NamePath;
}

export const ROUT_PREFIX = "->";
export const SelectAirport = ({
  name,
  label,
  size,
  required,
  disabled,
  rules,
  form,
  fullNamePath,
}: commonProps & SelectAirporI) => {
  const { data: airport } = useGetAllAirportQuery();
  const selectAirport = airport?.data;
  const [value, setValue] = useState<string | undefined>("");
  const [options, setOptions] = useState<IAirport[]>([]);

  const onSelect = (data: string) => {
    if (value?.includes(ROUT_PREFIX)) {
      setValue((prev) => {
        if (prev) {
          const temp = prev.split(ROUT_PREFIX);
          temp.pop();
          const finalValue = temp.join(ROUT_PREFIX);
          return finalValue + ROUT_PREFIX + `${data}`;
        }
        return data;
      });
    } else {
      setValue(data);
    }
  };

  const editTime = useWatch(fullNamePath, form);
  const { id } = useParams();

  useEffect(() => {
    if (id && !value) {
      setValue(editTime);
    } else {
      form && fullNamePath && form.setFieldValue(fullNamePath, value);
    }
  }, [value, id]);

  // Handle clear when add button press
  const current_data = useWatch(fullNamePath, form);
  useEffect(() => {
    if (!current_data) {
      setValue(undefined);
      form && fullNamePath && form.setFieldValue(fullNamePath, undefined);
    }
  }, [current_data]);

  return (
    <Col xs={24} sm={12} md={8} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
          ...(rules || []),
        ]}
      >
        <AutoComplete
          style={{ minWidth: 100 }}
          disabled={disabled}
          options={
            value
              ? options.map((item) => ({
                  value: item.airline_iata_code + ROUT_PREFIX,
                  label: `${item.airline_iata_code}-${item.airline_airport}`,
                }))
              : selectAirport?.map((item) => ({
                  value: item.airline_iata_code + ROUT_PREFIX,
                  label: `${item.airline_iata_code}-${item.airline_airport}`,
                }))
          }
          onSelect={onSelect}
          onSearch={(text) => {
            setValue(text);
            if (text) {
              const text2 = text.split(ROUT_PREFIX);
              setOptions(
                selectAirport?.filter((item) =>
                  `${item?.airline_airport?.toLowerCase()} ${
                    item?.airline_id
                  } ${item?.airline_iata_code?.toLowerCase()}`.includes(
                    text2[text2.length - 1].toLowerCase()
                  )
                ) || []
              );
            } else {
              setOptions(
                selectAirport?.filter((item) =>
                  `${item?.airline_airport?.toLowerCase()} ${
                    item?.airline_id
                  } ${item?.airline_iata_code?.toLowerCase()}`.includes(
                    text.toLowerCase()
                  )
                ) || []
              );
            }
          }}
          placeholder="input here"
        />
      </Form.Item>
    </Col>
  );
};

export const SelectAirportV1FormListID = ({
  name,
  label,
  size,
  required,
  disabled,
  rules,
  form,
  fullNamePath,
  PrevRouteIdName,
}: commonProps & SelectAirporI & { PrevRouteIdName: any }) => {
  const { data: airport } = useGetAllAirportQuery();
  const [value, setValue] = useState<any[]>([]);
  const [iataCode, setIataCode] = useState("");
  const [off, setOff] = useState(true);

  const prevIDFromEdit = PrevRouteIdName
    ? form.getFieldValue(PrevRouteIdName)
    : [];
  const reset = form.getFieldValue("airticket_route_or_sector_reset");
  const names = useWatch(fullNamePath, form);

  const getCountryNamesById = (ids: any) => {
    const countryNames: any = [];
    ids?.forEach((id: any) => {
      const match = airport?.data?.find((item) => item.airline_id === id);
      if (match) {
        countryNames.push(match.airline_iata_code);
      }
    });
    return countryNames;
  };
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleChange = (item: string[]) => {
    setOff(false);
    setValue([...value, item]);
  };

  const handleInputChange = (e: any) => {
    const IDsByName = getAirportByItaCode(names, airport?.data);
    setValue(IDsByName);
  };

  useEffect(() => {
    if (off && prevIDFromEdit) {
      setValue(prevIDFromEdit);
    }
  }, [prevIDFromEdit]);

  useEffect(() => {
    setIataCode(getCountryNamesById(value)?.join("->"));
  }, [value]);

  useEffect(() => {
    if (iataCode) {
      form.setFieldValue(fullNamePath, iataCode);
    }
  }, [iataCode]);

  return (
    <Col xs={24} sm={12} md={8} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
          ...(rules || []),
        ]}
      >
        <Input
          autoComplete="off"
          onChange={handleInputChange}
          addonBefore={
            <Select
              allowClear
              placeholder="Route"
              showSearch
              filterOption={filterOption}
              onChange={handleChange}
              options={airport?.data?.map((item) => ({
                value: item.airline_id as string,
                label: item.airline_iata_code,
              }))}
            />
          }
        />
      </Form.Item>
    </Col>
  );
};

export const SelectAirportV1FormListString = ({
  name,
  label,
  size,
  required,
  disabled,
  rules,
  form,
  fullNamePath,
  prevFiledName,
}: commonProps & SelectAirporI & { prevFiledName?: any }) => {
  const { id } = useParams();
  const { data: airport } = useGetAllAirportQuery();
  const [value, setValue] = useState<any[]>([]);
  const [iataCode, setIataCode] = useState("");
  const [off, setOff] = useState(true);

  // const prevIDFromEdit = form.getFieldValue('airticket_route_or_sector_string');
  const reset = form.getFieldValue("airticket_route_or_sector_reset");
  const names = useWatch(fullNamePath, form);

  const prevStingValue = form.getFieldValue(prevFiledName);

  // useEffect(() => {

  // }, [getIDbyName]);

  const getCountryNamesById = (ids: any) => {
    const countryNames: any = [];
    ids.forEach((id: any) => {
      const match = airport?.data?.find((item) => item.airline_id === id);
      if (match) {
        countryNames.push(match.airline_iata_code);
      }
    });
    return countryNames;
  };
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleChange = (item: string[]) => {
    setOff(false);
    setValue([...value, item]);
  };

  const handleInputChange = (e: any) => {
    const IDsByName = getAirportByItaCode(names, airport?.data);
    setValue(IDsByName);
  };

  useEffect(() => {
    if (off && prevStingValue) {
      setValue(getAirportByItaCode(prevStingValue, airport?.data));
    }
  }, [prevStingValue]);

  useEffect(() => {
    setIataCode(getCountryNamesById(value).join("->"));
  }, [value]);

  useEffect(() => {
    if (iataCode) {
      form.setFieldValue(fullNamePath, iataCode);
    }
  }, [iataCode]);

  return (
    <Col xs={24} sm={12} md={8} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
          ...(rules || []),
        ]}
      >
        <Input
          autoComplete="off"
          onChange={handleInputChange}
          addonBefore={
            <Select
              allowClear
              placeholder="Route"
              showSearch
              filterOption={filterOption}
              onChange={handleChange}
              options={airport?.data?.map((item) => ({
                value: item.airline_id as string,
                label: item.airline_iata_code,
              }))}
            />
          }
        />
      </Form.Item>
    </Col>
  );
};

export const SelectSingleAirport = ({
  name,
  label,
  size,
  required,
  disabled,
  rules,
  placeholder,
  onChange,
  onSelect,
}: commonProps) => {
  const { data: airport } = useGetAllAirportQuery();
  const selectAirport = airport?.data;

  let timeoutId: NodeJS.Timeout | null = null;

  return (
    <Col xs={24} sm={12} md={8} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
          ...(rules || []),
        ]}
      >
        <Select
          placeholder={placeholder ? placeholder : ` ${label}`}
          showSearch
          disabled={disabled}
          onChange={onChange}
          onSelect={onSelect}
          allowClear
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {selectAirport?.map((item, index) => (
            <Option key={index} value={item.airline_id}>
              {item.airline_iata_code}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectClient = ({
  name,
  label,
  size,
  required,
  placeholder,
  rules = [],
  showAll,
  xlSize,
  xsSize,
  mdSize,
  smSize,
  disabled,
  onChange,
  handleChnage,
}: commonProps) => {
  const { data: clients } = useGetClientsQuery({});
  const selectClient = clients?.data;
  const clientChildrens: React.ReactNode[] = [];
  if (selectClient) {
    for (let i = 0; i < selectClient.length; i++) {
      clientChildrens.push(
        <Select.Option
          key={selectClient[i].client_name + " " + selectClient[i].client_id}
          value={selectClient[i].client_id}
        >
          {selectClient[i].client_name}
        </Select.Option>
      );
    }
  }
  const allOption = (
    <Select.Option key="all" value="all">
      ALL
    </Select.Option>
  );

  showAll && clientChildrens.unshift(allOption);
  return (
    <Col
      span={6}
      xs={xsSize || 24}
      sm={smSize || 12}
      md={mdSize || 8}
      lg={size || 6}
      xl={xlSize}
    >
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
          ...rules,
        ]}
      >
        <Select
          disabled={disabled}
          placeholder={`Select ${label}`}
          showSearch
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          allowClear
        >
          {clientChildrens}
        </Select>
      </Form.Item>
    </Col>
  );
};
export const SelectAirline = ({
  name,
  label,
  size,
  required,
  placeholder,
  rules = [],
  showAll,
  xlSize,
  xsSize,
  mdSize,
  smSize,
  disabled,
  onChange,
  handleChnage,
}: commonProps) => {
  const { data: airline } = useGetAllAirlinesQuery();
  const selectAirline = airline?.data;
  const airlineChildrens: React.ReactNode[] = [];
  if (selectAirline) {
    for (let i = 0; i < selectAirline.length; i++) {
      airlineChildrens.push(
        <Select.Option
          key={
            selectAirline[i].airline_name +
            " " +
            selectAirline[i].airline_id +
            " " +
            selectAirline[i].serial
          }
          value={selectAirline[i].airline_id}
        >
          {selectAirline[i].airline_name}
        </Select.Option>
      );
    }
  }
  const allOption = (
    <Select.Option key="all" value="all">
      ALL
    </Select.Option>
  );

  showAll && airlineChildrens.unshift(allOption);
  return (
    <Col
      span={6}
      xs={xsSize || 24}
      sm={smSize || 12}
      md={mdSize || 8}
      lg={size || 6}
      xl={xlSize}
    >
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
          ...rules,
        ]}
      >
        <Select
          disabled={disabled}
          placeholder={`Select ${label}`}
          showSearch
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          allowClear
        >
          {airlineChildrens}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectTranportType = ({
  name,
  label,
  size,
  required,
  disabled,
}: commonProps) => {
  const { data: transportType } = useGetTransportTypeQuery();
  const selectData = transportType?.data;
  const selectOptioinChildren: React.ReactNode[] = [];
  if (selectData) {
    for (let i = 0; i < selectData.length; i++) {
      selectOptioinChildren.push(
        <Select.Option
          title=" "
          key={selectData[i].ttype_id + " " + selectData[i].ttype_name}
          value={selectData[i].ttype_id}
        >
          {selectData[i].ttype_name}
        </Select.Option>
      );
    }
  }
  return (
    <Col xs={24} sm={12} md={8} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
        ]}
      >
        <Select
          disabled={disabled}
          placeholder={"Select sales by"}
          showSearch
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          allowClear
        >
          {selectOptioinChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectMaharam = ({
  name,
  label,
  size,
  required,
  smSize,
  mdSize,
}: commonProps) => {
  const { data } = useGetAllMaharamQuery();
  const selectData = data?.data;
  const selectOptioinChildren: React.ReactNode[] = [];
  if (selectData) {
    for (let i = 0; i < selectData.length; i++) {
      selectOptioinChildren.push(
        <Select.Option
          key={selectData[i].maharam_id + " " + selectData[i].maharam_name}
          value={selectData[i].maharam_id}
        >
          {selectData[i].maharam_name}
        </Select.Option>
      );
    }
  }
  return (
    <Col
      span={6}
      xs={24}
      sm={smSize ? smSize : 24}
      md={mdSize ? mdSize : 24}
      lg={size ? size : 8}
    >
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
        ]}
      >
        <Select
          placeholder={"Select sales by"}
          showSearch
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          allowClear
        >
          {selectOptioinChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectAgency = ({ name, label, size, required }: commonProps) => {
  const { data } = useGetAllAgencyQuery();
  const selectData = data?.data;

  console.log(selectData);

  const selectOptioinChildren: React.ReactNode[] = [];
  if (selectData) {
    for (let i = 0; i < selectData.length; i++) {
      selectOptioinChildren.push(
        <Select.Option
          key={selectData[i].agency_id + " " + selectData[i].agency_name}
          value={selectData[i].agency_id}
        >
          {selectData[i].agency_name}
        </Select.Option>
      );
    }
  }
  return (
    <Col span={6} xs={24} sm={24} md={24} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
        ]}
      >
        <Select
          placeholder={"Select agency"}
          showSearch
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          allowClear
        >
          {selectOptioinChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectHajjigroup = ({
  name,
  label,
  size,
  required,
  placeholder,
  rules,
  dependencies,
  smSize,
  mdSize,
}: commonProps) => {
  const { data } = useGetAllGroupsQuery();
  const selectData = data?.data;
  const selectOptioinChildren: React.ReactNode[] = [];
  if (selectData) {
    for (let i = 0; i < selectData.length; i++) {
      selectOptioinChildren.push(
        <Select.Option
          key={selectData[i].group_id + " " + selectData[i].group_name}
          value={selectData[i].group_id}
        >
          {selectData[i].group_name}
        </Select.Option>
      );
    }
  }
  return (
    <Col
      xs={24}
      sm={smSize ? smSize : 12}
      md={mdSize ? mdSize : 8}
      lg={size ? size : 8}
    >
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
          ...(rules || []),
        ]}
        dependencies={dependencies}
      >
        <Select
          placeholder={placeholder}
          showSearch
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          allowClear
        >
          {selectOptioinChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

// SELECT COUNTRY CODE
export const SelectCountryCode = (
  <Select
    showSearch
    placeholder={CountryName[19].dialCode}
    optionFilterProp="roleMobile"
    filterOption={(input, option) =>
      (option!.children as unknown as string)
        .toLowerCase()
        .includes(input.toLowerCase())
    }
  >
    {mobileChildren}
  </Select>
);

// COMMON SELECT INPUT

type CustomInputProps = {
  data?: ISelectData[];
  defaultValue?: number | string;
  name?: NamePath;
  label?: string;
  required?: boolean;
  size?: number;
  mdSize?: number;
  smSize?: number;
  placeholder?: string;
  disabled?: boolean;
  small?: boolean;
  onChange?: React.Dispatch<React.SetStateAction<number | string>>;
  onDeselect?: any;
  onSearch?: (value: string) => void;
  mb?: number;
  searchText?: string;
  loading?: boolean;
  mode?: "multiple" | "tags";
  style?: CSSProperties;
  allowClear?: boolean;
  clientID?: string | undefined;
  setTableData?: React.Dispatch<React.SetStateAction<ITrackingNoGetAll[]>>;
  rules?: Rule[];
};

export const SelectDataInput = ({
  name,
  label,
  size,
  smSize,
  mdSize,
  required,
  data,
  placeholder,
  disabled,
  onChange,
  onSearch,
  small,
  defaultValue,
  mb,
  searchText,
  loading,
  mode,
  style,
  allowClear,
  rules,
  maxCount,
  onDeselect,
}: CustomInputProps & { maxCount?: number | undefined }) => {
  const childrens: React.ReactNode[] = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      childrens.push(
        <Select.Option
          key={data[i].title + " " + data[i].id}
          value={data[i].id}
        >
          {data[i].title}
        </Select.Option>
      );
    }
  }

  return (
    <Col
      style={{ marginBottom: mb }}
      xs={24}
      sm={smSize ? smSize : 24}
      md={mdSize ? mdSize : 8}
      lg={size ? size : 8}
    >
      <Form.Item
        style={{ padding: "0", margin: "0", ...style }}
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
          ...(rules || []),
        ]}
      >
        <Select
          onDeselect={onDeselect}
          mode={mode}
          size={small ? "small" : "middle"}
          placeholder={placeholder ? placeholder : label}
          showSearch
          maxCount={maxCount}
          searchValue={searchText || undefined}
          disabled={disabled}
          onChange={onChange}
          onSearch={onSearch}
          allowClear={allowClear ? true : false}
          defaultValue={defaultValue}
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          loading={loading || false}
        >
          {childrens}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectHajjTrackingNoByClient = ({
  name,
  label,
  size,
  smSize,
  mdSize,
  required,
  placeholder,
  disabled,
  onChange,
  onSearch,
  small,
  defaultValue,
  mb,
  searchText,
  loading,
  mode,
  style,
  allowClear,
  clientID,
  setTableData,
}: CustomInputProps) => {
  const [getTrackingNoByClientId, { data: trackingList, isLoading }] =
    useLazyGetTrackingNoByClientIdQuery();

  useEffect(() => {
    if (clientID) getTrackingNoByClientId({ clientId: clientID });
  }, [clientID]);

  return (
    <Col
      style={{ marginBottom: mb }}
      xs={24}
      sm={smSize ? smSize : 24}
      md={mdSize ? mdSize : 8}
      lg={size ? size : 8}
    >
      <Form.Item
        style={{ padding: "0", margin: "0", ...style }}
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
        ]}
      >
        <Select
          mode={mode}
          clearIcon
          size={small ? "small" : "middle"}
          placeholder={placeholder ? placeholder : label}
          showSearch
          searchValue={searchText || undefined}
          disabled={disabled}
          onChange={onChange}
          onSearch={onSearch}
          allowClear={allowClear ? true : false}
          defaultValue={defaultValue}
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.label as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          loading={isLoading || false}
          options={trackingList?.data?.map((item, index) => ({
            value: item.hajiinfo_tracking_number,
            label: item.hajiinfo_tracking_number,
          }))}
        />
      </Form.Item>
    </Col>
  );
};

// SELECT INPUT
type SelectProps = {
  options?: string[];
  style?: React.CSSProperties;
  onSelect?: (value: string) => void;
  onSearch?: (value: string) => void;
  rules?: Rule[] | undefined;
  defaultValue?: string | number | undefined;
  setOpeningType?: React.Dispatch<React.SetStateAction<string>>;
  items?: { label: string; value: number | string }[];
  allowClear?: boolean | undefined;
} & commonProps;

export const SelectOptionsInput = ({
  options,
  name,
  label,
  size,
  mdSize,
  smSize,
  onChange,
  disabled,
  required,
  placeholder,
  style,
  small,
  onSelect,
  rules,
  dependencies,
  defaultValue,
  setOpeningType,
  allowClear,
}: SelectProps) => {
  return (
    <Col
      span={6}
      xs={24}
      sm={smSize ? smSize : 12}
      md={mdSize ? smSize : 8}
      lg={size || 8}
    >
      <Form.Item
        dependencies={dependencies}
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
          ...(rules || []),
        ]}
        style={{ ...style }}
      >
        <Select
          placeholder={placeholder || `Select ${label}`}
          onChange={onChange}
          defaultValue={defaultValue}
          onSelect={(value) => onSelect && onSelect(value.toString())}
          showSearch
          disabled={disabled}
          size={small ? "small" : "middle"}
          allowClear={allowClear}
          onClear={() => setOpeningType && setOpeningType("")}
        >
          {options?.map((item, index) => {
            return (
              <Select.Option value={item} key={String(index)}>
                {item}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectItem = ({
  name,
  label,
  size,
  mdSize,
  smSize,
  onChange,
  disabled,
  required,
  placeholder,
  style,
  small,
  onSelect,
  rules,
  dependencies,
  defaultValue,
  setOpeningType,
  onSearch,
  items,
  loading,
}: SelectProps) => {
  return (
    <Col
      span={6}
      xs={24}
      sm={smSize ? smSize : 12}
      md={mdSize ? smSize : 8}
      lg={size || 8}
    >
      <Form.Item
        dependencies={dependencies}
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
          ...(rules || []),
        ]}
        style={{ ...style }}
      >
        <Select
          loading={loading}
          onSearch={onSearch}
          placeholder={placeholder || `Select ${label}`}
          onChange={onChange}
          defaultValue={defaultValue}
          onSelect={(value) => onSelect && onSelect(value.toString())}
          showSearch
          disabled={disabled}
          size={small ? "small" : "middle"}
          allowClear
          onClear={() => setOpeningType && setOpeningType("")}
        >
          {items?.map((item, index) => {
            return (
              <Select.Option value={item.value} key={String(index)}>
                {item.label}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </Col>
  );
};

// SELECT AGENTS

type agenData = {
  style?: React.CSSProperties;
  getAllAgentProfile?: AgentProfileDataType[];
  setInstanceAgentSelect?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  isPercent?: boolean;
} & commonProps;

export const SelectAgent = ({
  name,
  label,
  size,
  smSize,
  mdSize,
  disabled,
  required,
  placeholder,
  style,
  small,
  xlSize,
  xsSize,
  showAll,
  setInstanceAgentSelect,
  offDropDown,
  isPercent,
  xxl,
}: agenData) => {
  const { data } = useGetAllAgentProfileQuery();
  const getAllAgentProfile = data?.data;
  const children: React.ReactNode[] = [];

  if (getAllAgentProfile) {
    for (let i = 0; i < getAllAgentProfile.length; i++) {
      children.push(
        <Select.Option
          value={getAllAgentProfile[i].agent_id}
          key={
            getAllAgentProfile[i].agent_name +
            " " +
            getAllAgentProfile[i].agent_id +
            " " +
            getAllAgentProfile[i].agent_mobile
          }
        >
          <Space
            title={`${getAllAgentProfile[i].agent_name}${
              isPercent
                ? " [" +
                  Fixed2(getAllAgentProfile[i]?.agent_commission_rate) +
                  "%]"
                : ""
            }`}
          >
            {`${getAllAgentProfile[i].agent_name}${
              isPercent
                ? " [" +
                  Fixed2(getAllAgentProfile[i]?.agent_commission_rate) +
                  "%]"
                : ""
            }`}
          </Space>
        </Select.Option>
      );
    }
  }
  const [click, setClick] = useState<boolean>();
  const allOption = (
    <Select.Option key="all" value="all">
      ALL
    </Select.Option>
  );

  showAll && children.unshift(allOption);

  return (
    <Col
      span={6}
      xs={xsSize || 24}
      sm={smSize || 12}
      md={mdSize || 8}
      lg={size || 8}
      xl={xlSize}
      xxl={xxl}
    >
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
        ]}
        style={{ ...style }}
      >
        <Select
          placeholder={placeholder ? placeholder : `Select ${label}`}
          showSearch
          disabled={disabled}
          allowClear
          size={small ? "small" : "middle"}
          filterOption={(input, option) => {
            return (option!.key as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase());
          }}
          dropdownRender={
            offDropDown
              ? undefined
              : (menu) => (
                  <>
                    <CreateAgentModal
                      setClick={setClick}
                      setInstanceAgentSelect={setInstanceAgentSelect}
                    />
                    <Divider style={{ margin: "8px 0" }} />
                    {menu}
                  </>
                )
          }
          open={click === true ? false : undefined}
        >
          {children}
        </Select>
      </Form.Item>
    </Col>
  );
};
//only visa wise
export const SelectVisa = ({
  name,
  label,
  size,
  disabled,
  placeholder,
  onChange,
  showAll,
}: commonProps) => {
  const { data: visa } = useGetAllVisaListQuery();
  const selectClients = visa?.data;
  const visaChildren: React.ReactNode[] = [];
  if (selectClients) {
    for (let i = 0; i < selectClients.length; i++) {
      const { invcat_id, invcat_parentcat, invcat_title } = selectClients[i];

      visaChildren.push(
        <Select.Option
          title=" "
          key={invcat_parentcat + "  " + invcat_id}
          value={`${invcat_id}`}
        >
          {invcat_title}
        </Select.Option>
      );
    }
  }

  const allOption = (
    <Select.Option key="all" value="all">
      All
    </Select.Option>
  );

  showAll && visaChildren.unshift(allOption);
  return (
    <Col xs={24} sm={24} md={24} lg={size ? size : 8}>
      <Form.Item name={"visa_id"} label={"Select Visa"}>
        <Select dropdownStyle={{ color: "red" }} placeholder={"Select Visa"}>
          {visaChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

//only visa wise
export const SelectAccountList = ({
  name,
  label,
  size,
  disabled,
  placeholder,
  onChange,
  showAll,
}: commonProps) => {
  const { data: AccountList } = useGetAllAccountListQuery();
  const selectClients = AccountList?.data;
  const visaChildren: React.ReactNode[] = [];
  if (selectClients) {
    for (let i = 0; i < selectClients?.length; i++) {
      const { account_id, account_name, account_bank_name } = selectClients[i];

      visaChildren.push(
        <Select.Option
          title=" "
          key={account_name + "  " + account_id}
          value={`${account_id}`}
        >
          {account_name + (account_bank_name ? " - " + account_bank_name : "")}
        </Select.Option>
      );
    }
  }

  const allOption = (
    <>
      <Select.Option key="all" value="all">
        All
      </Select.Option>
      <Select.Option key="investments" value="investments">
        Investments
      </Select.Option>
      <Select.Option key="noninvoiceincome" value="noninvoiceincome">
        Non-Invoice Income
      </Select.Option>
      <Select.Option key="incentiveincome" value="incentiveincome">
        Incentive Income
      </Select.Option>
    </>
  );

  showAll && visaChildren.unshift(allOption);
  return (
    <Col xs={24} sm={24} md={24} lg={size ? size : 8}>
      <Form.Item name={"account_id"} label={"Select Account"}>
        <Select
          allowClear
          dropdownStyle={{ color: "red" }}
          placeholder={"Select Account"}
          showSearch
        >
          {visaChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectVendorsByInvId = ({
  name,
  label,
  size,
  mdSize,
  smSize,
  required,
  small,
  onChange,
  ticketInfoName,
  vendorInfoName,
  isPercentage,
  rules,
  disabled,
  selectVendor,
  dependencies,
  manualData,
  setInstanceVendorSelect,
  mode,
  offDropDown,
}: vendorProps) => {
  const vendorsChildren: React.ReactNode[] = [];

  // THIS FOR SPECIFIC/MANUAL VENDORS
  if (manualData?.length)
    for (let i = 0; i < manualData.length; i++) {
      vendorsChildren.push(
        <Select.Option
          key={
            manualData[i].vendor_name +
            "  " +
            manualData[i].vendor_id +
            " " +
            manualData[i]?.vendor_mobile
          }
          value={manualData[i].vendor_type + "-" + manualData[i].vendor_id}
        >
          {`${manualData[i].vendor_name} ${
            isPercentage
              ? ` [${manualData[i].vproduct_commission_rate || 0}%]`
              : ""
          }`}
        </Select.Option>
      );
    }

  const [click, setClick] = useState<boolean>();
  return (
    <Col
      xs={24}
      sm={smSize ? smSize : 12}
      md={mdSize ? mdSize : 8}
      lg={size ? size : 8}
    >
      <Form.Item
        dependencies={dependencies}
        name={name}
        label={label}
        required={false || required}
        rules={[
          ({ getFieldValue }) => ({
            required: required || false,
            message: `${label} is required!`,
          }),
          ({ getFieldValue }) => ({
            validator(_, value) {
              const vendorInfo: { itnrvendor_vendor_id: number | undefined }[] =
                getFieldValue([
                  "ticket_info",
                  Number(ticketInfoName),
                  "vendors",
                ]);

              if (
                vendorInfoName &&
                vendorInfoName > 0 &&
                vendorInfo.find(
                  (item, index) =>
                    index !== vendorInfoName &&
                    item !== undefined &&
                    item.itnrvendor_vendor_id === value
                )?.itnrvendor_vendor_id === value
              ) {
                return Promise.reject();
              } else {
                return Promise.resolve();
              }
            },
            message: "Cannot Select Duplicate Vendor!!",
          }),
          ...(rules || []),
        ]}
        labelCol={{ span: 24 }}
      >
        <Select
          onSelect={selectVendor}
          onChange={(e: string) => {
            onChange && onChange(e);
          }}
          size={small ? "small" : "middle"}
          allowClear
          placeholder={"Select Vendor"}
          showSearch
          disabled={disabled || false}
          filterOption={(input, option) =>
            (option!.key as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          mode={mode}
          dropdownRender={
            offDropDown
              ? undefined
              : (menu) => (
                  <>
                    <Divider style={{ margin: "8px 0" }} />
                    {menu}
                  </>
                )
          }
          open={click === true ? false : undefined}
        >
          {vendorsChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

interface IClientInvoiceOrTicket {
  client_id: string;
  receipt_payment_to: "INVOICE" | "TICKET" | "OVERALL";
  form: FormInstance<any>;
  index: number;
  paymentTo: "INVOICE" | "TICKET";
}
export const SelectClientsInvoicesById = ({
  name,
  label,
  required,
  disabled,
  placeholder,
  loading,
  rules,
  dependencies,
  client_id,
  receipt_payment_to,
  form,
  index,
  paymentTo,
}: commonProps & IClientInvoiceOrTicket) => {
  const { data: clientInvoicesInfo } = useGetClientInvoicesInfoQuery({
    id: client_id,
    receipt_payment_to,
  });
  const selectClients = clientInvoicesInfo?.data;

  const invoiceNo = useWatch(["invoices", index, "invoice_no"], form);
  const ticket_no = useWatch(["tickets", index, "ticket_no"], form);

  useEffect(() => {
    if (paymentTo === "INVOICE") {
      const findData = selectClients?.money_receipt.find(
        (item) => item.invoice_id === invoiceNo
      );
      form.setFields([
        {
          name: ["invoices", index, "invoiceDate"],
          value: findData?.invoice_date
            ? dayjs(findData?.invoice_date)
            : undefined,
        },
        {
          name: ["invoices", index, "netTotal"],
          value: findData?.invoice_net_total,
        },
        {
          name: ["invoices", index, "paid"],
          value: findData?.total_pay_amount,
        },
        {
          name: ["invoices", index, "due"],
          value: findData?.invoice_due,
        },
      ]);
    } else {
      const inv_id = ticket_no?.split("-")[0];
      const ticketNo = ticket_no?.split("-")[1];

      const findData = selectClients?.money_receipt.find(
        (item) =>
          item.invoice_id == inv_id && item.airticket_ticket_no === ticketNo
      );

      form.setFields([
        {
          name: ["tickets", index, "invoiceDate"],
          value: findData?.invoice_date
            ? dayjs(findData?.invoice_date)
            : undefined,
        },
        {
          name: ["tickets", index, "netTotal"],
          value: findData?.invoice_net_total,
        },
        {
          name: ["tickets", index, "paid"],
          value: findData?.total_pay_amount,
        },
        {
          name: ["tickets", index, "due"],
          value: findData?.ticket_due,
        },
      ]);
    }
  }, [invoiceNo, ticket_no, selectClients]);

  const clientsChildren: React.ReactNode[] = [];

  if (selectClients?.money_receipt?.length) {
    selectClients?.money_receipt?.forEach((element, index) => {
      clientsChildren.push(
        <Select.Option
          title=" "
          key={element?.invoice_id}
          value={
            receipt_payment_to === "INVOICE"
              ? element.invoice_id
              : `${element.invoice_id}-${element.airticket_ticket_no}`
          }
        >
          {receipt_payment_to === "INVOICE"
            ? element.invoice_no
            : element.airticket_ticket_no}
        </Select.Option>
      );
    });
  }

  return (
    <Col span={6} xs={24} sm={12} md={8} lg={4}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          { required: required, message: "Client required" },
          ...(rules || []),
        ]}
        dependencies={dependencies}
      >
        <Select
          loading={loading || false}
          dropdownStyle={{ color: "red" }}
          disabled={disabled}
          placeholder={placeholder ? placeholder : label}
          showSearch
          allowClear
          style={{ padding: "0", margin: "0", border: "0" }}
          filterOption={(input, option) =>
            (option!.key as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {clientsChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectClientsInvoicesByIdForEdit = ({
  name,
  label,
  required,
  disabled,
  placeholder,
  loading,
  rules,
  dependencies,
  client_id,
  receipt_payment_to,
  index,
  form,
}: commonProps & IClientInvoiceOrTicket) => {
  const { data: clientInvoicesInfo } = useGetClientInvoicesInfoEditQuery({
    id: client_id,
    receipt_payment_to,
  });

  const invoiceNo = useWatch(["invoices", index, "invoice_no"], form);
  const ticket_no = useWatch(["tickets", index, "ticket_no"], form);

  const paymentTo = form.getFieldValue("receipt_payment_to");

  useEffect(() => {
    if (paymentTo === "INVOICE") {
      const findData = selectClients?.money_receipt.find(
        (item) => item.invoice_id === invoiceNo
      );

      if (findData)
        form.setFields([
          {
            name: ["invoices", index, "invoiceDate"],
            value: findData?.invoice_date
              ? dayjs(findData?.invoice_date)
              : undefined,
          },
          {
            name: ["invoices", index, "netTotal"],
            value: findData?.invoice_net_total,
          },
          {
            name: ["invoices", index, "paid"],
            value: findData?.total_pay_amount,
          },
          {
            name: ["invoices", index, "due"],
            value: findData?.invoice_due,
          },
        ]);
    } else {
      const inv_id = ticket_no?.split("-")[0];
      const ticketNo = ticket_no?.split("-")[1];

      const findData = selectClients?.money_receipt.find(
        (item) =>
          item.invoice_id == inv_id && item.airticket_ticket_no === ticketNo
      );
      if (findData)
        form.setFields([
          {
            name: ["tickets", index, "invoiceDate"],
            value: findData?.invoice_date
              ? dayjs(findData?.invoice_date)
              : undefined,
          },
          {
            name: ["tickets", index, "netTotal"],
            value: findData?.invoice_net_total,
          },
          {
            name: ["tickets", index, "paid"],
            value: findData?.total_pay_amount,
          },
          {
            name: ["tickets", index, "due"],
            value: findData?.invoice_due,
          },
        ]);
    }
  }, [invoiceNo, ticket_no]);

  const selectClients = clientInvoicesInfo?.data;

  const clientsChildren: React.ReactNode[] = [];

  if (selectClients) {
    selectClients?.money_receipt?.forEach((element, index) => {
      clientsChildren.push(
        <Select.Option
          title=" "
          key={element?.invoice_id}
          value={
            receipt_payment_to === "INVOICE"
              ? element.invoice_id
              : `${element.invoice_id}-${element.airticket_ticket_no}`
          }
        >
          {receipt_payment_to === "INVOICE"
            ? element.invoice_no
            : element.airticket_ticket_no}
        </Select.Option>
      );
    });
  }

  return (
    <Col span={6} xs={24} sm={12} md={8} lg={4}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          { required: required, message: "Client required" },
          ...(rules || []),
        ]}
        dependencies={dependencies}
      >
        <Select
          loading={loading || false}
          dropdownStyle={{ color: "red" }}
          disabled={disabled}
          placeholder={placeholder ? placeholder : label}
          showSearch
          allowClear
          style={{ padding: "0", margin: "0", border: "0" }}
          filterOption={(input, option) =>
            (option!.key as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {clientsChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectGender = ({ name, label, size, required }: commonProps) => {
  return (
    <Col span={6} xs={24} sm={12} md={12} lg={size || 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required || false, message: "Gender is required" }]}
      >
        <Select
          showSearch
          allowClear
          placeholder="Select gender"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {genderChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

type Props = {
  label: string;
  name: string;
  required?: boolean;
  options: string[];
  size?: number;
};

export const AutoComplete_input = ({
  label,
  name,
  required,
  options,
  size,
}: Props) => {
  return (
    <Col xs={24} sm={12} md={12} lg={size || 12} xl={size || 12}>
      <Form.Item
        label={label}
        name={name}
        rules={[{ required, message: `Please select ${label}` }]}
      >
        <AutoComplete
          style={{ width: "100%" }}
          placeholder={label}
          options={options?.map((Item) => ({
            value: Item,
          }))}
          filterOption={(inputValue, option) =>
            option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
        >
          <Input name={name} required={required} />
        </AutoComplete>
      </Form.Item>
    </Col>
  );
};

export const SelectAirportV1 = ({
  name,
  label,
  size,
  required,
  disabled,
  rules,
  form,
  fullNamePath,
}: commonProps & SelectAirporI) => {
  const { data: airport } = useGetAllAirportQuery();
  const [value, setValue] = useState<any[]>([]);
  const [iataCode, setIataCode] = useState("");
  const [off, setOff] = useState(true);

  const prevIDFromEdit = form.getFieldValue("airticket_route_or_sector_id");
  const reset = form.getFieldValue("airticket_route_or_sector_reset");
  const namesValue = useWatch(name, form);

  const IDsByName = getAirportByItaCode(namesValue, airport?.data);

  //   useEffect(() => {
  // if(!name){

  // }

  //   }, [namesValue])

  const getCountryNamesById = (ids: any) => {
    const countryNames: any = [];
    ids.forEach((id: any) => {
      const match = airport?.data?.find((item) => item.airline_id === id);
      if (match) {
        countryNames.push(match.airline_iata_code);
      }
    });
    return countryNames;
  };
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleChangeSelect = (item: string[]) => {
    setOff(false);
    setValue([...value, item]);
  };

  const handleInputChange = (e: any) => {
    setValue(IDsByName);
  };

  useEffect(() => {
    if (!namesValue) {
      form.setFieldValue(name, undefined);
      setValue([]);
      setIataCode("");
    }
  }, [namesValue]);

  useEffect(() => {
    if (off && prevIDFromEdit) {
      setValue(prevIDFromEdit);
    }
  }, [prevIDFromEdit]);

  useEffect(() => {
    setIataCode(getCountryNamesById(value).join("->"));
  }, [value]);

  useEffect(() => {
    if (iataCode) {
      form.setFieldValue(name, iataCode);
    } else {
      form.setFieldValue(name, undefined);
    }
  }, [iataCode]);

  return (
    <Col xs={24} sm={12} md={8} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
          ...(rules || []),
        ]}
      >
        <Input
          autoComplete="off"
          onChange={handleInputChange}
          addonBefore={
            <Select
              allowClear
              placeholder="Route"
              popupMatchSelectWidth={200}
              showSearch
              filterOption={filterOption}
              onChange={handleChangeSelect}
              options={airport?.data?.map((item) => ({
                value: item.airline_id as any,
                label: `${item.airline_iata_code}`,
              }))}
            />
          }
        />
      </Form.Item>
    </Col>
  );
};

export const SelectBSPFile = ({
  name,
  label,
  size,
  mdSize,
  smSize,
  placeholder,
  required,
  headID,
  showAll,
  setSubHeadId,
  onChange,
}: SelectExpenseProps) => {
  const [search, setSearch] = useState<string | undefined>();
  const { data } = useSelectBspFileListQuery({ search });
  const bspFile = data?.data;

  let timeoutId: NodeJS.Timeout | null = null;

  const handleSearch = (value: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      if (value !== "") setSearch(value);
    }, 400);
  };

  return (
    <Col
      span={6}
      xs={24}
      sm={smSize ? smSize : 24}
      md={mdSize ? mdSize : 24}
      lg={size ? size : 8}
    >
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required, message: "IATA file is required" }]}
      >
        <Select
          onChange={onChange}
          placeholder={placeholder || "Select IATA File"}
          showSearch
          onSearch={handleSearch}
          allowClear
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) => {
            return (option?.label?.toLowerCase() ?? "").includes(
              input.toLowerCase()
            );
          }}
          options={bspFile?.map((item, index) => ({
            value: item.bsp_id,
            label: item.bsp_file_name,
          }))}
        />
      </Form.Item>
    </Col>
  );
};

export const SelectAllAccount = ({
  name,
  label,
  size,
  placeholder,
  required,
  showAll,
  onSelect,
}: commonProps) => {
  const { data: users } = useGetAllUsersQuery();

  const SelectUsers = users?.data;
  const userChildrens: React.ReactNode[] = [];

  if (SelectUsers) {
    for (let i = 0; i < SelectUsers.length; i++) {
      userChildrens.push(
        <Select.Option
          key={SelectUsers[i].user_full_name + " " + SelectUsers[i].user_id}
          value={SelectUsers[i].user_id}
        >
          {`${SelectUsers[i].user_full_name}`}
        </Select.Option>
      );
    }
  }

  const allOption = (
    <Select.Option key="all" value="all">
      ALL
    </Select.Option>
  );

  showAll && userChildrens?.unshift(allOption);

  return (
    <Col span={6} xs={24} sm={24} md={24} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[{ required: required, message: "Users is required" }]}
      >
        <Select
          allowClear={true}
          placeholder={placeholder || "Select User"}
          onSelect={onSelect}
          showSearch
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {userChildrens}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectInvoiceByClientID = ({
  name,
  label,
  size,
  required,
  small,
  onChange,
  rules,
  disabled,
  dependencies,
  clientId,
  allowClear,
  getSelectedInvoice,
}: commonProps & {
  clientId: string;
  getSelectedInvoice?: React.Dispatch<
    React.SetStateAction<IPartialRefundInvoice | undefined>
  >;
}) => {
  const [refundableInvoice, { data: refundable_invoices }] =
    useLazyGetRefundableInvoiceQuery();

  useEffect(() => {
    if (clientId) {
      refundableInvoice(clientId);
    }
  }, [clientId]);

  const handleSelect = (value: string) => {
    const invoiceData = refundable_invoices?.data?.find(
      (item) => Number(item.invoice_id) === Number(value)
    );

    getSelectedInvoice && getSelectedInvoice(invoiceData);
  };

  return (
    <Col xs={24} sm={12} md={8} lg={size ? size : 8}>
      <Form.Item
        dependencies={dependencies}
        name={name}
        label={label}
        required={false || required}
        rules={[
          ({ getFieldValue }) => ({
            required: required || false,
            message: `${label} is required!`,
          }),
          ...(rules || []),
        ]}
      >
        <Select
          onChange={(e: string) => {
            onChange && onChange(e);
          }}
          onSelect={handleSelect}
          size={small ? "small" : "middle"}
          allowClear={allowClear}
          placeholder={"Select Invoice"}
          showSearch
          disabled={disabled || false}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {refundable_invoices?.data?.map((item, index) => {
            return (
              <Select.Option value={item.invoice_id} key={String(index)}>
                {item.invoice_no}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const SelectTicketByInvoiceID = ({
  name,
  label,
  size,
  required,
  small,
  onChange,
  rules,
  disabled,
  dependencies,
  invoice_id,
  allowClear,
  mode,
}: commonProps & {
  invoice_id: string;
  mode?: "multiple" | "tags" | undefined;
}) => {
  const [fetchTicketsByInvoice, { data: air_tickets }] =
    useLazyGetRefundableTicketsQuery();

  useEffect(() => {
    if (invoice_id) {
      fetchTicketsByInvoice(invoice_id);
    }
  }, [invoice_id]);

  return (
    <Col xs={24} sm={12} md={8} lg={size ? size : 8}>
      <Form.Item
        dependencies={dependencies}
        name={name}
        label={label}
        required={false || required}
        rules={[
          ({ getFieldValue }) => ({
            required: required || false,
            message: `${label} is required!`,
          }),
          ...(rules || []),
        ]}
      >
        <Select
          onChange={(e: string) => {
            onChange && onChange(e);
          }}
          mode={mode}
          size={small ? "small" : "middle"}
          allowClear={allowClear}
          placeholder={"Select Invoice"}
          showSearch
          disabled={disabled || false}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {air_tickets?.data?.map((item, index) => {
            return (
              <Select.Option value={item?.airticket_id} key={String(index)}>
                {item.airticket_no}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </Col>
  );
};

// SELECT SALES BY

export const SelectBspPeriod = ({
  name,
  label,
  size,
  required,
  showAll,
  setInstanceSalesEmpSelect,
  offDropDown,
  vendorId,
  disabled,
}: commonProps & { vendorId: any }) => {
  const formatted_vendor = vendorId?.split("-")[1];

  const { data } = useGetBspPeriodQuery({ vendor_id: formatted_vendor });

  return (
    <Col span={6} xs={24} sm={12} md={8} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
        ]}
      >
        <Select
          placeholder={"Select BSP Period"}
          showSearch
          disabled={disabled}
          allowClear
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          options={data?.data?.map((item) => ({
            value: item,
            label: item,
          }))}
        />
      </Form.Item>
    </Col>
  );
};
