import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
} from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import { Rule } from "antd/es/form";
import TextArea from "antd/es/input/TextArea";
import { NamePath } from "antd/lib/form/interface";
import dayjs from "dayjs";
import { ReactNode } from "react";
import { IntRange } from "../../../common/types/commonTypes";
import { disabledFeatureDate } from "../../../helpers/CapitalizeFirstLetter";
import { mobileChildren } from "../../../modules/Client/Client/utils/utils_tsx/utilAddClient";
import { SelectCountryCode } from "./SelectCustomFeilds";
import { rangePresets } from "../../../common/utils/common.utils";

type Props = {
  color?: "green" | "red" | "gray";
  defaultValue?: any;
  dialCodeName?: NamePath;

  value?: any;
  disabled?: boolean;
  help?: string;
  icon?: boolean;
  label?: string | undefined;
  labelExtra?: ReactNode;
  name?: NamePath;
  loading?: boolean;
  maxAmount?: number;
  mdSize?: number;
  min?: string;
  max?: string | number;
  offset?: number;
  onChange?: React.Dispatch<React.SetStateAction<number | string>>;
  onBlur?: (value: any) => void;
  onPressEnter?: (e: any) => void;
  onClick?: (e: any) => void;
  placeholder?: string;
  rangePicker?: boolean;
  readOnly?: boolean;
  required?: boolean;
  smSize?: number;
  size?: number;
  small?: boolean;
  style?: React.CSSProperties;
  setShowTable?: any;
  inputType?: "text" | "email" | "tel";
  onKeyUp?: any;
  type?: "default" | "link" | "text" | "dashed" | "primary" | undefined;
  validateStatus?: boolean;
  year?: boolean;
  rules?: Rule[] | undefined;
  className?: string;
  autoSize?: boolean;
  minChar?: IntRange<0, 15>;
  maxChar?: IntRange<0, 15>;
  textAlign?: "right" | "left" | "center" | "start" | "end";
  dependencies?: NamePath[];
  setPasscheck?: React.Dispatch<
    React.SetStateAction<string | number | undefined>
  >;
  removeSpace?: true;
  allowClear?: boolean;
  status?: boolean;
  disablePrevDate?: boolean;
  disableFeatureDate?: boolean;
  hidden?: boolean;
  disableSpecificDate?: any;
  xxl?: number;
};
const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  // Can not select days before today
  return current && current < dayjs().startOf("day");
};
// FORM DATE INPUT ITEM
export function DateInput({
  name,
  label,
  size,
  mdSize,
  required,
  year,
  placeholder,
  disabled,
  rangePicker,
  smSize,
  readOnly,
  dependencies,
  rules,
  disablePrevDate,
  disableFeatureDate,
  disableSpecificDate,
  onChange,
  allowClear,
  xxl,
  defaultValue,
  value,
}: Props) {
  // const today = new Date().toISOString().slice(0, 10);
  const { RangePicker } = DatePicker;

  const dateDisable = () => {
    if (disablePrevDate) return disabledDate;
    else if (disableFeatureDate) return disabledFeatureDate;
    else if (disableSpecificDate) return disableSpecificDate;
    return undefined;
  };

  return (
    <Col xs={24} sm={smSize || 12} md={mdSize || 8} lg={size || 8} xxl={xxl}>
      <Form.Item
        dependencies={dependencies}
        rules={[
          {
            required: required,
            message: `${label} is required!`,
            type: !rangePicker ? "date" : "array",
          },
          ...(rules || []),
        ]}
        name={name}
        label={label}
      >
        {!rangePicker ? (
          <DatePicker
            readOnly={readOnly}
            disabledDate={dateDisable()}
            picker={year ? "year" : "date"}
            format={year ? "YYYY" : "DD-MM-YYYY"}
            placeholder={placeholder || "Select date"}
            style={{ width: "100%" }}
            disabled={disabled}
            onChange={onChange}
            defaultValue={defaultValue}
            value={value}
          />
        ) : (
          <RangePicker
            value={value}
            style={{ width: "100%" }}
            disabled={disabled}
            presets={rangePresets}
            picker={year ? "year" : "date"}
            format={year ? "YYYY" : "DD-MM-YYYY"}
            onChange={onChange as any}
            allowClear={allowClear}
            defaultValue={defaultValue}
          />
        )}
      </Form.Item>
    </Col>
  );
}

// FORM BUTTON SUBMIT
export const FormButton = ({
  label,
  size,
  mdSize,
  smSize,
  type,
  disabled,
  icon,
  loading,
  style,
  className,
  textAlign,
  removeSpace,
  onClick,
}: Props) => {
  return (
    <Form.Item label={removeSpace ? false : []} colon={false}>
      <Col
        span={6}
        xs={24}
        sm={smSize ? smSize : 24}
        md={mdSize ? mdSize : 24}
        lg={size || 24}
        className={className}
        style={{ textAlign: textAlign || "right" }}
      >
        <Button
          type={type || "primary"}
          htmlType="submit"
          disabled={disabled}
          loading={loading}
          style={style}
          onClick={onClick}
        >
          {icon && <SearchOutlined />}
          {label}
        </Button>
      </Col>
    </Form.Item>
  );
};

// FORM INPUT ITEM
export const FormInputItem = ({
  name,
  label,
  required,
  size,
  smSize,
  mdSize,
  placeholder,
  readOnly,
  onChange,
  help,
  offset,
  validateStatus,
  inputType,
  dependencies,
  onKeyUp,
  setPasscheck,
  onBlur,
  onPressEnter,
  allowClear,
  status,
  disabled,
  min,
  hidden,
  rules = [],
  labelExtra,
  xxl,
}: Props) => {
  return (
    <Col
      hidden={hidden || false}
      xs={24}
      sm={smSize ? smSize : 12}
      md={mdSize ? mdSize : 8}
      lg={{ span: size || 8, offset: offset || 0 }}
      xxl={xxl}
    >
      <Form.Item
        dependencies={dependencies}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },

          ...(rules || []),
        ]}
        name={name}
        label={labelExtra ? labelExtra : label}
        required={required}
        validateStatus={validateStatus ? "error" : "validating"}
        help={validateStatus ? help : null}
      >
        <Input
          status={status! ? "error" : ""}
          allowClear={allowClear}
          onKeyUp={onKeyUp}
          readOnly={readOnly}
          type={inputType || "text"}
          placeholder={placeholder || label}
          onBlur={onBlur}
          onPressEnter={onPressEnter}
          onChange={(e) => {
            onChange && onChange(e.target.value);
            setPasscheck && setPasscheck(e.target.value);
          }}
          disabled={disabled}
        />
      </Form.Item>
    </Col>
  );
};

// FORM NUMBER INPUT ITEM

export const NumberInput = ({
  name,
  label,
  mdSize,
  required,
  smSize,
  size,
  small,
  readOnly,
  color,
  maxAmount,
  placeholder,
  style,
  defaultValue,
  onChange,
  min,
  offset,
  max,
  maxChar,
  minChar,
  rules,
  disabled,
  xxl,
  hidden,
}: Props) => {
  return (
    <Col
      xs={24}
      sm={smSize || 12}
      md={mdSize || 8}
      lg={{ span: size || 8 }}
      xxl={xxl}
      offset={offset ? offset : 0}
      hidden={hidden || false}
    >
      <Form.Item
        name={name}
        label={label}
        required={required}
        rules={
          readOnly
            ? [
                {
                  validator: (_, value) => {
                    if (value || value === 0) {
                      value = value + "";
                      const maximum = "9".repeat(maxChar || 0);
                      const minimum =
                        minChar && minChar > 1
                          ? "1" + "0".repeat(minChar - 1)
                          : "0";
                      if (maxChar && minChar) {
                        if (
                          Number(value || 0) <= Number(maximum) &&
                          Number(value || 0) >= Number(minimum)
                        ) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            `Minimum ${minChar}, Maximum ${maxChar} digit allowed`
                          );
                        }
                      } else if (maxChar) {
                        if (Number(value || 0) <= Number(maximum)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            `Maximum ${maxChar} digit allowed`
                          );
                        }
                      } else if (minChar) {
                        if (Number(value || 0) >= Number(minimum)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            `Minimum ${minChar} digit allowed`
                          );
                        }
                      }
                      return Promise.resolve();
                    } else {
                      return Promise.resolve();
                    }
                  },
                },

                ...(rules || []),
              ]
            : [
                ({ getFieldValue }) => ({
                  validator: (_, value) => {
                    if (getFieldValue("invoice_due") < value) {
                      return Promise.reject();
                    } else {
                      return Promise.resolve();
                    }
                  },
                  message: "Amount is not more than DueAmount",
                }),
                {
                  required: required || false,
                  message: `${label} is required!`,
                },

                {
                  validator: async (_, value) => {
                    if (
                      maxAmount &&
                      Number(value || 0) > Number(maxAmount || 0)
                    ) {
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  },
                  message: "Insufficient balance!",
                },
                {
                  validator: (_, value) => {
                    if (value || value === 0) {
                      value = value + "";
                      const maximum = "9".repeat(maxChar || 0);
                      const minimum =
                        minChar && minChar > 1
                          ? "1" + "0".repeat(minChar - 1)
                          : "0";
                      if (maxChar && minChar) {
                        if (
                          Number(value || 0) <= Number(maximum) &&
                          Number(value || 0) >= Number(minimum)
                        ) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            `Minimum ${minChar}, Maximum ${maxChar} digit allowed`
                          );
                        }
                      } else if (maxChar) {
                        if (Number(value || 0) <= Number(maximum)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            `Maximum ${maxChar} digit allowed`
                          );
                        }
                      } else if (minChar) {
                        if (Number(value || 0) >= Number(minimum)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            `Minimum ${minChar} digit allowed`
                          );
                        }
                      }
                      return Promise.resolve();
                    } else {
                      return Promise.resolve();
                    }
                  },
                },

                ...(rules || []),
              ]
        }
      >
        <InputNumber
          size={small ? "small" : "middle"}
          placeholder={placeholder ? placeholder : label}
          style={{
            width: "100%",
            border: `1px solid ${color}`,
            color,
            ...style,
          }}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
          min={min || undefined}
          max={max || undefined}
          // type={'number'}
          readOnly={readOnly || false}
          defaultValue={defaultValue ? defaultValue : undefined}
          onChange={(e) => onChange && onChange(Number(e))}
          disabled={disabled}
        />
      </Form.Item>
    </Col>
  );
};

// FORM MOBILE NUMBER INPUT ITEM
export const FormMobileNumberItem = ({
  size,
  required,
  dialCodeName,
  name,
  dependencies,
  rules,
  disabled,
}: Props) => {
  return (
    <Col xs={24} sm={12} md={8} lg={size || 8}>
      <Form.Item
        dependencies={dependencies}
        name={name ? name : "number"}
        label="Mobile No:"
        rules={[
          { required: required, message: "Number is required!" },
          { max: 15, message: "Max 15 characters!" },
          {
            message: "Only digits allowed",
            validator: (_, value) => {
              if (value) {
                if (/^[0-9]*$/.test(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject("Non-numeric values are not allowed");
                }
              } else {
                return Promise.resolve();
              }
            },
          },
          ...(rules || []),
        ]}
      >
        <Input
          disabled={disabled}
          addonBefore={
            <Form.Item
              style={{ paddingRight: "0", margin: "0" }}
              name={dialCodeName ? dialCodeName : ["dailCode"]}
              required
            >
              <Select
                showSearch
                style={{
                  padding: "0",
                  margin: "0",
                  border: "0",
                }}
                optionFilterProp="roleMobile"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                disabled={disabled}
              >
                {mobileChildren}
              </Select>
            </Form.Item>
          }
          placeholder="Mobile number"
        />
      </Form.Item>
    </Col>
  );
};

// CURRENCY FORMAT INPUT
export const NumberInputCurrencyFormat = ({
  readOnly,
  name,
  label,
  required,
  placeholder,
  size,
  onChange,
  maxChar,
  minChar,
  mdSize,
  smSize,
  hidden,
}: Props) => {
  return (
    <Col
      span={6}
      xs={24}
      sm={smSize ? smSize : 24}
      md={mdSize ? mdSize : 24}
      lg={size || 8}
      hidden={hidden || false}
    >
      <Form.Item
        rules={[
          {
            validator: (_, value) => {
              if (value) {
                if (/^([0-9]|\.)*$/.test(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject();
                }
              } else {
                return Promise.resolve();
              }
            },
            message: "Non-numeric values are not allowed",
          },
          {
            required: required !== undefined ? required : false,
            type: "number",
            message: `${label} is required!`,
          },
          {
            validator: (_, value) => {
              if (value || value === 0) {
                value = value + "";
                const maximum = "9".repeat(maxChar || 0);
                const minimum =
                  minChar && minChar > 1 ? "1" + "0".repeat(minChar - 1) : "0";
                if (maxChar && minChar) {
                  if (
                    Number(value || 0) <= Number(maximum) &&
                    Number(value || 0) >= Number(minimum)
                  ) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      `Minimum ${minChar}, Maximum ${maxChar} digit allowed`
                    );
                  }
                } else if (maxChar) {
                  if (Number(value || 0) <= Number(maximum)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(`Maximum ${maxChar} digit allowed`);
                  }
                } else if (minChar) {
                  if (Number(value || 0) >= Number(minimum)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(`Minimum ${minChar} digit allowed`);
                  }
                }
                return Promise.resolve();
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
        name={name}
        label={label}
      >
        <InputNumber
          required={required}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
          readOnly={readOnly}
          placeholder={placeholder || label}
          style={{ width: "100%" }}
          onChange={(value) => onChange && onChange(Number(value))}
        />
      </Form.Item>
    </Col>
  );
};

// TEXTAREA INPUT ITEM
export function TextAreaInput({ name, label, size, offset, autoSize }: Props) {
  return (
    <Col
      span={6}
      xs={24}
      sm={24}
      md={24}
      lg={size || 16}
      offset={offset ? offset : 0}
    >
      <Form.Item name={name} label={label}>
        <TextArea
          rows={3}
          placeholder="Note something"
          autoSize={autoSize}
          maxLength={255}
        />
      </Form.Item>
    </Col>
  );
}

// MOBILE INPUT
type MobileProps = {
  name?: NamePath;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  size?: number;
  smSize?: number;
  mdSize?: number;
  email?: boolean;
  rules?: Rule[];
  number?: boolean;
  placeholder?: string;
  setHandleTicketNo?: Function;
  readonly?: boolean;
  onBlur?: Function;
  onChange?: Function;
  validateStatus?: boolean;
  help?: string;
  resetField?: {
    fieldKey?: number | undefined;
  };
  countryCode?: boolean;
  countryCodeFormName?: NamePath;
  labelStyle?: React.CSSProperties;
  inputFieldStyle?: React.CSSProperties;
  small?: boolean;
  offset?: number;
  styleCol?: React.CSSProperties;
};

export const MobileInput = ({
  name,
  label,
  required,
  size,
  mdSize,
  smSize,
  rules = [],
  number,
  placeholder,
  setHandleTicketNo,
  readonly,
  resetField,
  onBlur,
  onChange,
  validateStatus,
  help,
  countryCode,
  countryCodeFormName,
  inputFieldStyle,
  small,
  offset,
  styleCol,
}: MobileProps) => {
  return (
    <Col
      style={styleCol}
      xs={24}
      sm={smSize ? smSize : 12}
      md={mdSize ? mdSize : 8}
      lg={{ span: size || 8, offset: offset || 0 }}
    >
      <Form.Item
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },

          () => ({
            validator(_, value) {
              if (number && value) {
                if (/^([0-9]|\.)*$/.test(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject("Non-numeric values are not allowed");
                }
              } else {
                return Promise.resolve();
              }
            },
          }),
          ...rules,
        ]}
        name={name}
        label={label}
        validateStatus={validateStatus ? "error" : "validating"}
        help={validateStatus ? help : null}
      >
        {
          <Input
            type="text"
            size={small ? "small" : "middle"}
            addonBefore={
              countryCode && (
                <Form.Item noStyle name={countryCodeFormName}>
                  {SelectCountryCode}
                </Form.Item>
              )
            }
            readOnly={readonly}
            placeholder={placeholder || label}
            onBlur={(e) => {
              onBlur && onBlur(e.target.value);
            }}
            style={{
              width: "100%",
              ...inputFieldStyle,
            }}
            {...resetField}
            onChange={(e) => {
              setHandleTicketNo && setHandleTicketNo(e);
              onChange && onChange(e);
            }}
          />
        }
      </Form.Item>
    </Col>
  );
};

// FORM INPUT
type FormProps = {
  name?: NamePath;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  size?: number;
  smSize?: number;
  mdSize?: number;
  email?: boolean;
  rules?: Rule[];
  number?: boolean;
  placeholder?: string;
  setHandleTicketNo?: Function;
  readonly?: boolean;
  onBlur?: Function;
  onChange?: Function;
  validateStatus?: boolean;
  help?: string;
  resetField?: {
    fieldKey?: number | undefined;
  };
  countryCode?: boolean;
  countryCodeFormName?: NamePath;
  labelStyle?: React.CSSProperties;
  inputFieldStyle?: React.CSSProperties;
  small?: boolean;
  offset?: number;
  styleCol?: React.CSSProperties;
  max?: number;
  className?: string;
  hidden?: boolean;
};

export const FormInput = ({
  disabled,
  name,
  label,
  required,
  size,
  rules = [],
  number,
  placeholder,
  setHandleTicketNo,
  readonly,
  resetField,
  onBlur,
  onChange,
  validateStatus,
  help,
  countryCode,
  countryCodeFormName,
  inputFieldStyle,
  small,
  offset,
  styleCol,
  mdSize,
  smSize,
  max,
  className,
  hidden,
}: FormProps) => {
  return (
    <Col
      hidden={hidden || false}
      style={styleCol}
      xs={24}
      sm={smSize ? smSize : 12}
      md={mdSize ? mdSize : 8}
      lg={{ span: size || 8, offset: offset || 0 }}
    >
      <Form.Item
        rules={[
          ...rules,
          {
            required: required || false,
            message: `${label} is required!`,
          },

          () => ({
            validator(_, value) {
              if (number && value) {
                if (/^([0-9-]|\.)*$/.test(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject("Non-numeric values are not allowed");
                }
              } else {
                return Promise.resolve();
              }
            },
          }),
        ]}
        name={name}
        label={label}
        validateStatus={validateStatus ? "error" : "validating"}
        help={validateStatus ? help : null}
      >
        {
          <Input
            allowClear
            max={max || undefined}
            size={small ? "small" : "middle"}
            addonBefore={
              countryCode && (
                <Form.Item noStyle name={countryCodeFormName}>
                  {SelectCountryCode}
                </Form.Item>
              )
            }
            readOnly={readonly}
            disabled={disabled}
            placeholder={placeholder || label}
            onBlur={(e) => {
              onBlur && onBlur(e.target.value);
            }}
            style={{
              width: "100%",
              ...inputFieldStyle,
            }}
            className={`${className ? className : ""}`}
            {...resetField}
            onChange={(e) => {
              setHandleTicketNo && setHandleTicketNo(e.target.value);
              onChange && onChange(e.target.value);
            }}
          />
        }
      </Form.Item>
    </Col>
  );
};

export const FormInputPassword = ({
  name,
  label,
  required,
  size,
  placeholder,
  offset,
}: Props) => {
  return (
    <Col xs={24} sm={12} md={8} lg={{ span: size || 8, offset: offset || 0 }}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required ? true : false,
            message: "Please enter your password",
          },

          {
            validator: (_, value) => {
              if (value && value.length < 8) {
                return Promise.reject(
                  "Password must be at least 8 characters long"
                );
              }
              const pattern = /^(?=.*[!@#$%^&*])/;

              if (value && !pattern.test(value)) {
                return Promise.reject(
                  "password must contain at least one special character and number"
                );
              }

              return Promise.resolve();
            },
          },
        ]}
        hasFeedback
      >
        <Input.Password
          autoComplete="new-password"
          placeholder={placeholder ? placeholder : "Enter password"}
        />
      </Form.Item>
    </Col>
  );
};
