import { Col, Form, Select } from "antd";

type Props = {
  accountsLastBalance?: any;
  name: string;
  label?: string;
  size?: number;
  mdSize?: number;
  smSize?: number;
  onSelect?: (value: number) => void;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  onChange?: React.Dispatch<React.SetStateAction<number>> | any;
  xxl?: number;
};

const AccountLastBalanceInput = ({
  accountsLastBalance,
  name,
  size,
  label,
  onSelect,
  disabled,
  required,
  onChange,
  placeholder,
  mdSize,
  smSize,
  xxl,
}: Props) => {
  return (
    <Col
      span={6}
      xs={24}
      sm={smSize ? smSize : 24}
      md={mdSize ? mdSize : 24}
      lg={size || 8}
      xxl={xxl}
    >
      <Form.Item
        name={name}
        label={label}
        required={required}
        rules={[
          {
            required: required,
            message: `${label} is required!`,
          },
        ]}
      >
        <Select
          allowClear
          onChange={onChange}
          placeholder={placeholder || `Select ${label}`}
          onSelect={onSelect}
          showSearch
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          disabled={disabled && disabled}
        >
          {accountsLastBalance?.map((item: any) => {
            return (
              <Select.Option
                value={item.account_id}
                key={item.account_id + " " + item.account_name}
              >
                {item.account_name +
                  (item?.account_bank_name
                    ? " - " + item.account_bank_name
                    : "")}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </Col>
  );
};

export default AccountLastBalanceInput;
