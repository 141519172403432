import { Col, Divider, Form, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { useEffect, useState } from "react";
import CreateClientModal from "../../../components/common/CreateClientModal";
import { useLazyGetAllCombClientsQuery } from "../../Client/Client/api/endpoints/clientEndpoints";

type clientInfo = {
  id?: string;
  name?: string;
  phone?: string;
};

type Props = {
  form: FormInstance<any>;
  setClientInfo: React.Dispatch<React.SetStateAction<clientInfo[]>>;
};

const SelectClientWithPhoneNo = ({ form, setClientInfo }: Props) => {
  // Fetch Client List
  const [click, setClick] = useState<boolean>();

  const [fetchCombClient, { isLoading, isFetching, data: clients }] =
    useLazyGetAllCombClientsQuery();

  useEffect(() => {
    fetchCombClient("");
  }, []);

  let timeoutId: NodeJS.Timeout | null = null;

  const handleSearch = (value: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      fetchCombClient(value);
    }, 300);
  };

  const handleClientSelect = (value: string[]) => {
    const result = value.map((item) => {
      const [id, name, phone] = item.split(" + ");
      return { id, name, phone };
    });
    setClientInfo(result);
  };
  return (
    <div>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Form.Item
          name={"client_id"}
          rules={[{ required: true, message: "Client required" }]}
        >
          <Select
            mode="multiple"
            onSearch={(value) => handleSearch(value)}
            loading={isLoading || isFetching}
            dropdownStyle={{ color: "red" }}
            showSearch
            placeholder={"Select Client"}
            allowClear={true}
            onChange={handleClientSelect}
            filterOption={(input, option) =>
              (option!.key as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            dropdownRender={(menu) => (
              <>
                <CreateClientModal setClick={setClick} />
                <Divider style={{ margin: "8px 0" }} />
                {menu}
              </>
            )}
            open={click === true ? false : undefined}
          >
            {clients?.data?.map((item, index) => (
              <Select.Option
                key={item.client_name + "  " + item.client_id}
                value={`${item.client_type}-${item.client_id} + ${item.client_name} + ${item.client_mobile}`}
              >
                <div>
                  <p>{item.client_name + ` - (${item.client_entry_id})`}</p>

                  <p>{item.client_mobile}</p>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </div>
  );
};

export default SelectClientWithPhoneNo;
