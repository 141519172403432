import { ColumnType } from "antd/es/table";
import React from "react";
import {
  IPartialRefundBodyType,
  IPartialRefundVendorInfo,
} from "../../../RefundTypes/RefundTypes";
import { Fixed2 } from "../../../../../common/utils/common.utils";

export const PartialRefundVendorUtils =
  (): ColumnType<IPartialRefundVendorInfo>[] => {
    return [
      {
        title: "SL.",
        render: (value, record, index) => index + 1,
      },

      {
        title: "Ticket No",
        key: "vprfnd_ticket_no",
        dataIndex: "vprfnd_ticket_no",
        align: "center",
      },
      {
        title: "Base Fare",
        key: "vprfnd_base_fare",
        dataIndex: "vprfnd_base_fare",
        render: (curr) => Fixed2(curr),
        align: "center",
      },
      {
        title: "Used Fare",
        key: "vprfnd_used_base_fare",
        dataIndex: "vprfnd_used_base_fare",
        render: (curr) => Fixed2(curr),
        align: "center",
      },
      {
        title: "Tax",
        key: "vprfnd_tax",
        dataIndex: "vprfnd_tax",
        render: (curr) => Fixed2(curr),
        align: "center",
      },

      {
        title: "Used Tax",
        key: "vprfnd_used_tax",
        dataIndex: "vprfnd_used_tax",
        render: (curr) => Fixed2(curr),
        align: "center",
      },
      {
        title: "Commission",
        key: "vprfnd_total_commission",
        dataIndex: "vprfnd_total_commission",
        render: (curr) => Fixed2(curr),
        align: "center",
      },

      {
        title: "AIT",
        key: "vprfnd_ait",
        dataIndex: "vprfnd_ait",
        render: (curr) => Fixed2(curr),
        align: "center",
      },
      {
        title: "Refund Charge",
        key: "vprfnd_charge_amount",
        dataIndex: "vprfnd_charge_amount",
        render: (curr) => Fixed2(curr),
        align: "center",
      },
      {
        title: "Return Amount",
        key: "vprfnd_return_amount",
        dataIndex: "vprfnd_return_amount",
        render: (curr, rec) => {
          return Fixed2(rec.vprfnd_return_amount);
        },
        align: "center",
      },
    ];
  };
