import { CopyOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Table, Typography, message } from "antd";
import { ColumnsType } from "antd/es/table";
import { FormInstance } from "antd/lib/form";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { selectUser } from "../../../auth/states/userSlice";
import { Fixed2 } from "../../../common/utils/common.utils";
import { FormButton } from "../../../components/common/FormItem/FormItems";
import { useGetAllAgentProfileQuery } from "../../Client/Agents_Profile/api/endpoints/agentProfileEndPoints";
import { useGetAllAirportQuery } from "../../Configuration/airport/endpoints/airportEndpoints";
import { useEditInvoiceAirticketMutation } from "../api/endpoints/airticketInvoiceEndpoints";
import {
  AirTicketInvoiceData,
  AirTicket_InvoicePostType,
  AirTicket_ticket_information,
} from "../types/invoiceAirTicketTypes";
import { getAgentCommissionRate } from "../utils/ts/getAgentCommissionRate";
import { postInvoiceAirticketDataFormat } from "../utils/ts/postInvoiceAirticketDataFormat";
import InvoiceAirSubTotal from "./InvoiceAirSubTotal";
import InvPostIsDiscountIsPrevDue from "./InvPostIsDiscountIsPrevDue";
import { useCheckCreditLimitMutation } from "../../Client/Client/api/endpoints/clientEndpoints";

type Props = {
  invoices: AirTicketInvoiceData[];
  setInvoices: React.Dispatch<React.SetStateAction<AirTicketInvoiceData[]>>;
  form: FormInstance<any>;
  setEditIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  edit_data?: AirTicket_InvoicePostType;
  editIndex?: number;
  ticketInfo?: AirTicket_ticket_information[];
};

export default function EditOverview({
  invoices,
  setInvoices,
  form,
  setEditIndex,
  edit_data,
  editIndex,
  ticketInfo,
}: Props) {
  // @STATES
  const [billingForm] = Form.useForm();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const { airticketInvoiceId } = useParams();
  const [editInvoiceAirticket, { isError, isLoading, isSuccess, data }] =
    useEditInvoiceAirticketMutation();
  const [updateAndPrev, setUpdateAndPrev] = useState<boolean>(false);
  const [checkCreditLimit, { isLoading: limiLoading }] =
    useCheckCreditLimitMutation();

  // @airport data
  const { data: airport } = useGetAllAirportQuery();
  const selectAirport = airport?.data;

  // @VARIABLES
  const client_price = invoices.reduce(
    (a, b) => a + (b.is_deleted === 1 ? 0 : Fixed2(b?.airticket_client_price)),
    0
  );

  const purchase_price = invoices.reduce(
    (a, b) =>
      a + (b.is_deleted === 1 ? 0 : Fixed2(b?.airticket_purchase_price)),
    0
  );
  const profit = invoices.reduce(
    (a, b) => a + (b.is_deleted === 1 ? 0 : Fixed2(b?.airticket_profit)),
    0
  );

  // @HANDLE COPY
  const handleCopy = (index: number) => {
    form.setFieldsValue(invoices[index]);
    setEditIndex(undefined);
    message.info("Copied");
  };

  // @HANDLE EDIT
  const handleEdit = (index: number) => {
    form.setFieldsValue({
      ...invoices[index],
      taxes_commission: invoices[index].taxes_commission?.length
        ? invoices[index].taxes_commission
        : [{}],
    });

    setEditIndex(index);
  };

  // @HANDLE DELETE
  const handleDelete = (index: number) => {
    if (ticketInfo && index > ticketInfo?.length - 1) {
      setInvoices(invoices.filter((_, i) => i !== index));
    } else {
      setInvoices((prev) => {
        const deletedInv = invoices.filter((item) => item?.is_deleted === 1);
        return prev.map((item, i) => {
          if (i === index) {
            if (prev.length > deletedInv.length) {
              return {
                ...item,
                is_deleted: Number(!Boolean(item.is_deleted)) as 0 | 1,
              };
            } else {
              message.error("Minimum 1 Ticket is required");
            }
          }
          return item;
        });
      });
    }
    setEditIndex(undefined);
  };

  // @TABLE COLUMNS
  const column: ColumnsType<AirTicketInvoiceData> = [
    {
      width: 15,
      title: "SL",
      render(_, __, index) {
        return <>{index + 1}</>;
      },
    },
    {
      title: "Ticket No",
      dataIndex: "airticket_ticket_no",
      key: "airticket_ticket_no",
    },
    {
      title: "Sales",
      dataIndex: "airticket_client_price",
      key: "airticket_client_price",
      render(value, record, index) {
        return Fixed2(value);
      },
      align: "right",
    },
    {
      title: "Purchase",
      dataIndex: "airticket_purchase_price",
      key: "airticket_purchase_price",
      render(value, record, index) {
        return Fixed2(value);
      },
      align: "right",
    },
    {
      title: "Profit",
      dataIndex: "airticket_profit",
      key: "airticket_profit",
      render(value, record, index) {
        return Fixed2(value);
      },
      align: "right",
    },
    {
      title: "Action",
      render(value, record, index) {
        return (
          <div>
            {record.is_deleted !== 1 ? (
              <Button
                title="Copy"
                onClick={() => handleCopy(index)}
                size="small"
                type="text"
              >
                <CopyOutlined />
              </Button>
            ) : (
              ""
            )}
            {!record?.airticket_is_refund ? (
              <>
                {record.is_deleted !== 1 ? (
                  <Button
                    title="Edit"
                    onClick={() => handleEdit(index)}
                    size="small"
                    type="text"
                  >
                    <EditOutlined />
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  title="Delete"
                  onClick={() => handleDelete(index)}
                  size="small"
                  type="text"
                >
                  <DeleteOutlined />
                </Button>
              </>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  // @HANDLE SUBMIT

  const handleSubmit = (data: any) => {
    const formattedData = postInvoiceAirticketDataFormat({
      invoices,
      billing_form: billingForm,
      user_id: user?.user_id as number,
      selectAirport,
      ticketInfoPrev: ticketInfo,
    });

    if (airticketInvoiceId) {
      const prevNetTotal = Fixed2(edit_data?.invoice_info?.invoice_net_total);
      const currentNetTotal = Fixed2(
        formattedData?.invoice_info?.invoice_net_total
      );
      if (prevNetTotal >= currentNetTotal) {
        editInvoiceAirticket({
          body: formattedData,
          airticketInvoiceId: airticketInvoiceId,
        });
      } else {
        checkCreditLimit({
          amount: currentNetTotal - prevNetTotal,
          combClient: formattedData.invoice_info.invoice_combclient_id,
        }).then((res: any) => {
          if (res?.data?.data === 1) {
            editInvoiceAirticket({
              body: formattedData,
              airticketInvoiceId: airticketInvoiceId,
            });
          } else {
            message.error(res?.data?.data);
          }
        });
      }
    }
  };

  // @AFTER CREATE SHOW ALERT
  useEffect(() => {
    if (isSuccess) {
      message.success("Invoice Updated Successfully");
      navigate(
        updateAndPrev
          ? `/airticket/details/${airticketInvoiceId}`
          : "/airticket",
        {
          state: "/airticket",
        }
      );
    }
  }, [isSuccess]);

  // GET ALL AGENT
  const { data: agentData } = useGetAllAgentProfileQuery();
  const getAllAgentProfile = agentData?.data;

  useEffect(() => {
    billingForm.setFieldValue("setInvoice_show_prev_due", 0);

    // @HANDLE AGENT COMISSION
    if (!airticketInvoiceId) {
      if (invoices[invoices?.length - 1]?.invoice_agent_id) {
        const agentCom =
          getAgentCommissionRate({
            allAgents: getAllAgentProfile,
            invoice_agent_id: invoices[invoices?.length - 1]?.invoice_agent_id,
          }) || 0;
        const commission = Fixed2((agentCom / 100) * (client_price || 0));

        billingForm.setFieldValue("invoice_agent_com_amount", commission);
      } else {
        billingForm.setFieldValue("invoice_agent_com_amount", undefined);
      }
    }
  }, [invoices]);

  // @EDIT TIME FILLUP DATA
  useEffect(() => {
    const billingData = edit_data?.invoice_info;
    billingForm.setFieldsValue({
      invoice_discount: Fixed2(billingData?.invoice_discount) || undefined,
      invoice_service_charge:
        Fixed2(billingData?.invoice_service_charge) || undefined,
      invoice_vat: Fixed2(billingData?.invoice_vat) || undefined,
      invoice_net_total: Fixed2(billingData?.invoice_net_total) || undefined,
      invoice_agent_com_amount:
        Fixed2(billingData?.invoice_agent_com_amount) || undefined,
      invoice_note: billingData?.invoice_note || undefined,
      invoice_show_prev_due: billingData?.invoice_show_prev_due,
      invoice_show_discount: billingData?.invoice_show_discount,
      invoice_show_unit: billingData?.invoice_show_unit || 0,
    });
  }, [edit_data]);

  return (
    <Row gutter={[10, 0]}>
      <Table
        style={{ width: "100%" }}
        size="small"
        columns={column}
        dataSource={invoices}
        pagination={false}
        bordered
        rowClassName={(record, index) => {
          let className = "";

          if (record?.airticket_is_refund) {
            className = "refund-row-style";
          }
          if (index === editIndex) {
            className = "record-edit-active";
          }
          if (record?.is_deleted === 1) {
            className = "deleted-edit-active";
          }
          return className;
        }}
        summary={(_) => {
          billingForm.setFieldsValue({ calc_profit: Fixed2(profit) });
          billingForm.setFieldsValue({ calc_sub_total: Fixed2(client_price) });
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={2} index={0}>
                <Typography style={{ textAlign: "right" }}>Total : </Typography>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <Typography.Text style={{ textAlign: "right" }}>
                  {Fixed2(client_price)}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4} align="right">
                <Typography.Text style={{ textAlign: "right" }}>
                  {Fixed2(purchase_price)}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <Typography.Text style={{ textAlign: "right" }}>
                  {Fixed2(profit)}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />

      <Form
        form={billingForm}
        onFinish={handleSubmit}
        layout="vertical"
        style={{ marginTop: "50px" }}
        disabled={!invoices?.length}
      >
        <InvoiceAirSubTotal
          form={billingForm}
          clientPriceSum={client_price}
          isAgentComission={
            invoices.length
              ? !invoices[invoices?.length - 1]?.invoice_agent_id
              : true
          }
        />

        <InvPostIsDiscountIsPrevDue billingForm={billingForm} />

        {/* @AIRTICKET POST BUTTON */}
        <Row gutter={[10, 10]}>
          <Col>
            <FormButton
              loading={!updateAndPrev && (isLoading || limiLoading)}
              disabled={!invoices?.length}
              label="UPDATE"
              onClick={() => {
                setUpdateAndPrev(false);
              }}
            />
          </Col>
          <Col>
            <FormButton
              loading={updateAndPrev && (isLoading || limiLoading)}
              disabled={!invoices?.length}
              label="UPDATE & VIEW"
              onClick={() => {
                setUpdateAndPrev(true);
              }}
            />
          </Col>
        </Row>
      </Form>
    </Row>
  );
}
