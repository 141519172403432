import { message } from "antd";

const assyncWrapper = async (cb: () => Promise<void>) => {
  try {
    await cb();
  } catch (err: any) {
    console.log({ err });

    if (err.error) {
      if (err.error.status === 400) {
        message.error(err.error.data.message);
      } else {
        message.error("Something went wrong! Please try again later.");
      }
    }
  }
};

export default assyncWrapper;
