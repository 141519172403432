import Typography from "antd/es/typography/Typography";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { IAccountStatement } from "../../AccountsTypes/AccountsTypes";
import { Fixed2 } from "../../../../common/utils/common.utils";
import { GetPayMethod } from "../../../../common/utils/CommonUtils";

type Props = {
  pagination: {
    current: number;
    pageSize: number;
  };
};

export const ListOfAccountsStatementColumn = ({
  pagination,
}: Props): ColumnsType<IAccountStatement> => {
  return [
    {
      title: "SL.",
      dataIndex: "key",
      align: "center",
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: "Date",
      key: "actransaction_date",
      render: (_, record) => {
        return `${dayjs(record.acctrxn_created_at).format("DD MMM YYYY")}`;
      },
    },
    {
      title: "Vouchar No.",
      dataIndex: "acctrxn_voucher",
      key: "acctrxn_voucher",
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "Particulars",
      dataIndex: "trxntype_name",
      key: "trxntype_name",
    },
    {
      title: "Pay Method",
      dataIndex: "acctrxn_pay_type",
      render: (field) => <GetPayMethod field={field} />,
      key: "acctrxn_pay_type",
    },

    {
      title: "Debit",
      dataIndex: "acctrxn_type",
      key: "acctrxn_type",
      align: "right",
      render: (_, record) => {
        if (record.acctrxn_type === "DEBIT") {
          return (
            <Typography style={{ color: "red" }}>
              {Number(record.acctrxn_amount || 0)}
            </Typography>
          );
        }
        return <Typography style={{ color: "red" }}>0</Typography>;
      },
    },
    {
      title: "Credit",
      dataIndex: "acctrxn_type",
      key: "acctrxn_type",
      align: "right",
      render: (_, record) => {
        if (record.acctrxn_type === "CREDIT") {
          return (
            <Typography style={{ color: "green" }}>
              {Number(record.acctrxn_amount || 0)}
            </Typography>
          );
        }
        return <Typography style={{ color: "green" }}>0</Typography>;
      },
    },
    {
      title: "Last Balance",
      dataIndex: "acctrxn_lbalance",
      key: "acctrxn_lbalance",
      align: "right",
      render: (amount) => {
        return (
          <p
            style={{
              color:
                Number(amount) === 0
                  ? "#18b4e9"
                  : Number(amount) > 0
                  ? "green"
                  : "red",
            }}
          >
            {Number(amount) > 0 ? "" : "Due "}

            {Fixed2(Math.abs(Number(amount)))}
          </p>
        );
      },
    },
    {
      title: "Details",
      dataIndex: "acctrxn_note",
      key: "acctrxn_note",
      render: (column, data) => (
        <span>
          {data?.acctrxn_note?.split("\n")?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
      width: 250,
      align: "justify",
    },
    {
      title: "Created Date",
      key: "acctrxn_created_date",
      dataIndex: "acctrxn_created_date",
      render: (acctrxn_created_date) => {
        return `${dayjs(acctrxn_created_date).format("DD MMM YYYY")}`;
      },
    },
    {
      title: "Created By",
      key: "user_name",
      dataIndex: "user_name",
    },
  ];
};
