import { message } from "antd";
import { api } from "../../../../app/baseQuery";
import { HTTPResponse } from "../../../../common/types/commonTypes";

import {
  getForViewAllReIssueInvoiceType,
  nonCommitionInvoicePostType,
} from "../../../Invoice_Non_Comission/types/nonInvoiceAirTicketTypes";
import { INVALIDATE_CLIENT_VENDOR_LEDGER } from "../../../Reports/Api/constants";
import { IExistingClients } from "../../Components/PreviousTicketInfo";
import {
  IExistingClPostData,
  IInvoice_info_Existing,
} from "../../Type/InoviceReissue.interface";
import { RE_ISSUE_AIRTICKET_ID } from "../constants/constants";
import assyncWrapper from "../../../../common/utils/assyncWrapper";

export const reIssueExistingAirTicketEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getInvoiceNo: build.query<
      HTTPResponse<{
        invoice_no: string;
      }>,
      void
    >({
      query: () => ({ url: "/invoice-air-ticket/generate-invoice-no" }),
      providesTags: [
        {
          type: "ReIssueAirTicket",
          id: RE_ISSUE_AIRTICKET_ID,
        },
      ],
    }),
    getAllReIssueInvoice: build.query<
      HTTPResponse<getForViewAllReIssueInvoiceType[]>,
      void
    >({
      query: () => ({ url: "/invoice-air-ticket/all_by_cate/3" }),
      providesTags: [
        {
          type: "ReIssueAirTicket",
          id: RE_ISSUE_AIRTICKET_ID,
        },
      ],
    }),
    getSingleReIssueInvoice: build.query<
      HTTPResponse<nonCommitionInvoicePostType>, //using noncomission type for similar fields
      number
    >({
      query: (id) => ({
        url: `/reissue/${id}`,
      }),
      providesTags: [{ type: "ReIssueAirTicket", id: RE_ISSUE_AIRTICKET_ID }],
    }),

    // ============== @Nazmul===========
    getExistingClients: build.query<HTTPResponse<IExistingClients[]>, string>({
      query: (id) => ({
        url: `/reissue/existing-client/${id}`,
      }),
      providesTags: [{ type: "ReIssueAirTicket", id: RE_ISSUE_AIRTICKET_ID }],
    }),

    postExistingClient: build.mutation<
      HTTPResponse<{ invoice_id: number }>,
      IInvoice_info_Existing
    >({
      query: (body) => ({
        url: `/reissue/post/existing/`,
        method: "POST",
        body: body,
      }),
      onQueryStarted: async (_arg, { queryFulfilled }) => {
        assyncWrapper(async () => {
          await queryFulfilled;
        });
      },
      invalidatesTags: [
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        { type: "ReIssueAirTicket", id: RE_ISSUE_AIRTICKET_ID },
        "BSPSummary",
      ],
    }),

    editExistingClient: build.mutation<
      HTTPResponse<void>,
      {
        body: IInvoice_info_Existing;
        id: number;
      }
    >({
      query: ({ body, id }) => ({
        url: `/reissue/existing-edit/${id}`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: [
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        "ReIssueAirTicket",
        { type: "ReIssueAirTicket", id: RE_ISSUE_AIRTICKET_ID },
        "BSPSummary",
      ],
    }),

    // Void Re-issue AirTicket
    voidReIssInvAirTicket: build.mutation<
      void,
      {
        body: {
          invoice_has_deleted_by: number | undefined;
          void_charge: number;
          id: number;
        };
      }
    >({
      query: ({ body }) => ({
        url: `/reissue/void/${body.id}`,
        method: "PUT",
        body: body,
      }),

      transformErrorResponse: (response) => {
        if (response.status === 400 && response.data) {
          const { message: err } = response.data as {
            message: string;
            success: boolean;
          };
          message.error(`${err}`);
        } else {
          message.error("Some things went to wrong");
        }
      },
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await queryFulfilled;
        message.success("Void Successfully");
      },

      invalidatesTags: [
        "ReIssueAirTicket",
        RE_ISSUE_AIRTICKET_ID,
        "invoices",
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),
  }),
});

export const {
  useLazyGetInvoiceNoQuery,
  useGetAllReIssueInvoiceQuery,
  useGetSingleReIssueInvoiceQuery,
  useLazyGetSingleReIssueInvoiceQuery,
  useGetExistingClientsQuery,
  useLazyGetExistingClientsQuery,
  usePostExistingClientMutation,
  useEditExistingClientMutation,
  useVoidReIssInvAirTicketMutation,
} = reIssueExistingAirTicketEndpoints;
