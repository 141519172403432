import { FileTextOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, Form, Row, Space, Table, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Fixed2, formatAmount } from "../../../../common/utils/common.utils";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import {
  DateInput,
  FormButton,
  FormInput,
} from "../../../../components/common/FormItem/FormItems";
import {
  SelectAccountList,
  SelectClients,
  SelectEmployee,
  SelectUsers,
} from "../../../../components/common/FormItem/SelectCustomFeilds";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import { landscapePageStyle } from "../../ledgers/pages/ClientLedgers";
import {
  useGetCollectionReportQuery,
  useLazyGetCollectionExcelQuery,
} from "../api/CollectionReportEndpoints";
import { CollectionColumn } from "../utils/CollectionColumn";

const { Text } = Typography;

type Props = {};

const CollectionReport = (props: Props) => {
  const [form] = useForm();
  const componentRef = useRef(null);

  const [info, setInfo] = useState<submitType>({
    client: "",
    employee_id: "",
    user_id: "",
    account_id: "",
    search: "",
    date_range: ["", ""],
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const [fetchExcel] = useLazyGetCollectionExcelQuery();
  const { data, isLoading, isFetching } = useGetCollectionReportQuery({
    ...info,
    page: pagination.current,
    size: pagination.pageSize,
    from_date:
      info?.date_range?.length &&
      dayjs(info.date_range[0]).format("YYYY-MM-DD"),
    to_date:
      info?.date_range?.length &&
      dayjs(info.date_range[1]).format("YYYY-MM-DD"),
  });

  const result = data?.data?.results;

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const onFinish = (values: submitType) => {
    setInfo(values);
    setPagination((prev) => ({ ...prev, current: 1, pageSize: 50 }));
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: landscapePageStyle,
  });
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const daily = searchParams.get("daily");
    const fromDate = searchParams.get("from_date");
    const toDate = searchParams.get("to_date");

    if (daily) {
      form.setFieldValue("date_range", [dayjs(daily), dayjs(daily)]);
    } else if (fromDate && toDate) {
      form.setFieldValue("date_range", [dayjs(fromDate), dayjs(toDate)]);
    } else {
      form.setFieldValue("date_range", [dayjs(), dayjs()]);
    }
  }, []);

  const jsx = (
    <>
      <Table
        dataSource={result}
        columns={CollectionColumn(pagination)}
        size="small"
        bordered
        pagination={
          data?.count !== undefined && data?.count > 50
            ? {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ["20", "50", "100", "200", "500"],
                onChange: handlePaginationChange,
              }
            : false
        }
        summary={(_) => {
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={8}>
                <div style={{ textAlign: "right", marginRight: 10 }}>
                  <Typography.Text strong>
                    Total Received Amount{"  "}
                  </Typography.Text>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={0} colSpan={1}>
                <div style={{ textAlign: "right", marginRight: 5 }}>
                  <Typography.Text strong className="color-collection nowrap">
                    {formatAmount(Fixed2(data?.data?.summary.total_amount))}
                  </Typography.Text>
                </div>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={0} colSpan={2} />
            </Table.Summary.Row>
          );
        }}
      />
    </>
  );

  const printExtraJsx = (
    <>
      <Row justify={"space-between"}>
        <Text>You have total :{data?.count} rows</Text>
        <Text>
          From date:{" "}
          {info?.date_range?.length &&
            dayjs(info.date_range[0]).format("DD-MMM-YYYY")}
        </Text>
        <Text>
          To date:{" "}
          {info?.date_range?.length &&
            dayjs(info.date_range[1]).format("DD-MMM-YYYY")}
        </Text>
        <Text>Search by: {info.search || "N/A"}</Text>
      </Row>
    </>
  );

  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx}
        printRef={componentRef}
        printExtraJsx={printExtraJsx}
        title={{
          info_title: "",
          title: "Collection Report",
        }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb
        arrOfOption={["Report", "Sales & Collection", "Collection Report"]}
      />
      <Space style={{ marginBottom: "1rem" }}>
        <Button type="primary" onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type="primary"
          onClick={() => {
            fetchExcel({
              ...info,
              from_date:
                info?.date_range?.length &&
                dayjs(info.date_range[0]).format("YYYY-MM-DD"),
              to_date:
                info?.date_range?.length &&
                dayjs(info.date_range[1]).format("YYYY-MM-DD"),
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          date_range: [dayjs(), dayjs()],
          client: "all",
        }}
      >
        <Row gutter={16}>
          <SelectClients
            name="client"
            label="Select Client"
            placeholder="Select client"
            size={3}
            showAll
            offDropDown
          />
          <SelectAccountList
            name={"account_id"}
            label="Select Account"
            size={4}
          />

          <SelectEmployee
            name={"employee_id"}
            size={3}
            label="Received By"
            offDropDown
          />
          <SelectUsers
            name={"user_id"}
            size={3}
            label="Prepared By"
            offDropDown
          />

          <DateInput
            rangePicker
            label="Date Range"
            name="date_range"
            size={5}
          />
          <FormInput name={"search"} size={3} label="Search" />
          <FormButton
            label="Search"
            icon
            textAlign="left"
            size={3}
            loading={isLoading || isFetching}
          />
        </Row>
      </Form>
      <Typography.Text>You have total :{data?.count} rows</Typography.Text>
      {jsx}
      {print_content}
    </>
  );
};

export default CollectionReport;

export interface submitType {
  client: string;
  employee_id: number | string;
  user_id: number | string;
  account_id: string;
  search: string;
  date_range: [string, string];
}
