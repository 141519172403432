import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Col } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { Link, useNavigate } from "react-router-dom";
import Reload, { refetchArr } from "../reload/Reload";

type Props = {
  arrOfOption: string[];
  refetch?: () => any;
  refetchArr?: refetchArr[];
  reloaderSize?: SizeType;
};

const BreadCrumb = ({
  arrOfOption,
  refetch,
  refetchArr,
  reloaderSize,
}: Props) => {
  const navigate = useNavigate();
  const breadcrumbItems = [
    {
      title: (
        <>
          <ArrowLeftOutlined
            onClick={() => navigate(-1)}
            className="icon_hover"
            style={{
              padding: "4px",
              borderRadius: "2px",
            }}
          />
          <Link to="/">
            <HomeOutlined />
          </Link>
        </>
      ),
    },
    {
      title: <Link to="/">DASHBOARD</Link>,
    },
    ...arrOfOption.map((item) => ({
      title: item.toUpperCase(),
      key: item,
    })),
  ];
  return (
    <div
      style={{
        padding: "10px",
        marginBottom: "20px",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Breadcrumb
        separator=">"
        items={[
          ...breadcrumbItems,
          {
            title: (
              <ArrowRightOutlined
                className="icon_hover"
                style={{
                  padding: "4px",
                  marginLeft: "5px",
                  borderRadius: "2px",
                }}
                onClick={() => navigate(1)}
              />
            ),
          },
        ]}
      />
      {refetch || refetchArr ? (
        <Col>
          <Reload
            refetch={refetch}
            refetchArr={refetchArr}
            reloaderSize={reloaderSize}
          />
        </Col>
      ) : (
        ""
      )}
    </div>
  );
};

export default BreadCrumb;
